import { setLocale } from 'yup';

import { createMessage } from './helpers';

setLocale({
  mixed: {
    required: (values) => createMessage('required', values, 'mixed'),
  },
  array: {
    length: (values) => createMessage('length', values, 'array'),
    min: (values) => createMessage('min', values, 'array'),
  },
  number: {
    max: (values) => createMessage('max', values, 'number'),
    min: (values) => createMessage('min', values, 'number'),
  },
  string: {
    length: (values) => createMessage('length', values, 'string'),
    max: (values) => createMessage('max', values, 'string'),
    min: (values) => createMessage('min', values, 'string'),
    uuid: (values) => createMessage('uuid', values, 'string'),
  },
});
