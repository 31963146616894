import React, { FC } from 'react';
import cx from 'classnames';

import { Override } from 'types/common';
import { TextProps } from 'types/components';

import Text from '../Text';

import styles from './styles.module.css';

type TruncateWidth = 'small' | 'middle';

type TruncateProps = Override<TextProps, {
  width?: TruncateWidth;
}>;

const Truncate: FC<TruncateProps> = ({
  className,
  title,
  children,
  width = 'small',
  ellipsis = true,
  ...props
}) => (
  <Text
    className={cx(
      styles.truncate,
      styles[`truncate_${width}`],
      className,
    )}
    title={title ?? (typeof children === 'string' ? children : undefined)}
    ellipsis={ellipsis}
    {...props}
  >
    {children}
  </Text>
);

export default Truncate;
