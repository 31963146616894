import React, { FC, useEffect } from 'react';

import { config } from 'data';
import { formatter } from 'helpers';
import { transactionBundleService } from 'services';
import { useAuth, useLang, useModal, useMutation, useQueryInvalidate } from 'hooks';
import { Can, TransactionBundleStatus } from 'components/layout';
import { Alert, Button, Descriptions, Form, Link, Modal, PopconfirmButton } from 'components/ui';
import { ModalBaseProps } from 'types/components';

import {
  TransactionBundleExtended,
  TransactionBundleStatus as ETransactionBundleStatus,
  UserPermission,
} from 'types/models';

import ApproveModal from './ApproveModal';
import DetailsModal from './DetailsModal';

type ViewBundleModalProps = ModalBaseProps & {
  bundle: TransactionBundleExtended;
};

const ViewBundleModal: FC<ViewBundleModalProps> = ({
  bundle,
  open,
  onClose,
}) => {
  const auth = useAuth();
  const lang = useLang();
  const approveModal = useModal();
  const detailsModal = useModal();
  const queryInvalidate = useQueryInvalidate();

  const invalidateBundleQueries = async () => {
    await queryInvalidate([config.TRANSACTION_BUNDLES_QUERY_KEY]);
    await queryInvalidate([config.TRANSACTION_BUNDLE_QUERY_KEY, bundle.id]);
  };

  const rejectBundleMutation = useMutation({
    mutationFn: () => transactionBundleService.rejectBundle(bundle.id),
    onSuccess: invalidateBundleQueries,
    successNotification: lang.get('transactionBundle.modal.rejectSuccess'),
  });

  const handleReject = async () => {
    await rejectBundleMutation.mutateAsync();

    onClose();
  };

  const isBundleContainsDataError = (bundle: TransactionBundleExtended) => {
    if (!bundle.errorText) {
      return false;
    }

    try {
      JSON.parse(bundle.errorText);

      return true;
    } catch (error) {
      return false;
    }
  };

  const isBundleContainsTextError = (bundle: TransactionBundleExtended) => {
    if (!bundle.errorText) {
      return false;
    }

    return !isBundleContainsDataError(bundle);
  };

  useEffect(() => {
    if (!open) {
      approveModal.hide();
      detailsModal.hide();
    }
  }, [open, approveModal, detailsModal]);

  const hasDataError = isBundleContainsDataError(bundle);
  const hasTextError = isBundleContainsTextError(bundle);

  const canEdit = auth.can(UserPermission.TRX_BUNDLES_MGMT);
  const isEditable = bundle.status === ETransactionBundleStatus.NEW;
  const hasActions = (canEdit && isEditable) || hasDataError;

  return (
    <Modal
      title={lang.get('transactionBundle.modal.businessAccountTitle', { name: bundle.client.name })}
      caption={lang.get('transactionBundle.modal.viewCaption')}
      cancelText={lang.get('common.actions.close')}
      okButtonProps={{ hidden: true }}
      open={open}
      onCancel={onClose}
    >

      <Form.ActionsItem hidden={!hasActions}>
        <Button type="default" hidden={!hasDataError} onClick={detailsModal.show}>
          {lang.get('transactionBundle.modal.actions.viewDetails')}
        </Button>
        <Can permissions={[UserPermission.TRX_BUNDLES_MGMT]}>
          <Button
            type="primary"
            ghost
            hidden={!isEditable}
            onClick={approveModal.show}
          >
            {lang.get('common.actions.approve')}
          </Button>
          <PopconfirmButton
            title={lang.get('transactionBundle.modal.rejectTitle')}
            danger
            hidden={!isEditable}
            loading={rejectBundleMutation.isPending}
            onConfirm={handleReject}
          >
            {lang.get('common.actions.reject')}
          </PopconfirmButton>
        </Can>
      </Form.ActionsItem>

      <Form.Divider hidden={!hasActions} />

      <Descriptions
        items={[{
          children: <Alert type="error" message={bundle.errorText} />,
          span: 2,
          hidden: !hasTextError,
        }, {
          label: lang.get('transactionBundle.modal.file.label'),
          children: <Link href={bundle.file.bucketUrl}>{bundle.file.originFileName}</Link>,
          span: 2,
        }, {
          label: lang.get('transactionBundle.modal.id.label'),
          children: bundle.id,
        }, {
          label: lang.get('transactionBundle.modal.externalId.label'),
          children: bundle.externalId,
        }, {
          label: lang.get('common.form.creationDate.label'),
          children: formatter.formatDateTime(bundle.createdAt),
        }, {
          label: lang.get('common.form.status.label'),
          children: <TransactionBundleStatus status={bundle.status} />,
        }]}
      />

      <ApproveModal
        bundle={bundle}
        open={approveModal.open}
        onClose={approveModal.hide}
        onSubmit={onClose}
      />

      <DetailsModal
        bundle={bundle}
        open={detailsModal.open}
        onClose={detailsModal.hide}
      />

    </Modal>
  );
};

export default ViewBundleModal;
