import { config } from 'data';
import { businessAccountService } from 'services';
import { useQuery } from 'hooks';
import { Uuid } from 'types/common';

const useAllBusinessAccountVirtualAccountsQuery = (id?: Uuid) => useQuery({
  queryKey: [config.BUSINESS_ACCOUNT_VIRTUAL_ACCOUNTS_QUERY_KEY, 'all', id],
  queryFn: () => id ? businessAccountService.getBusinessAccountVirtualAccounts(id) : null,
});

export default useAllBusinessAccountVirtualAccountsQuery;
