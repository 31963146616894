import React, { FC } from 'react';
import { Popover as BasePopover, PopoverProps } from 'antd';
import cx from 'classnames';

import styles from './styles.module.css';

const Popover: FC<PopoverProps> = ({ rootClassName, ...props }) => (
  <BasePopover rootClassName={cx(styles.root, rootClassName)} {...props} />
);

export default Popover;
