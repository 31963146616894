import React, { FC, ReactElement } from 'react';
import { Trans as BaseTrans } from 'react-i18next';

import { lang } from 'helpers';
import { Replace } from 'types/locales';

type TransProps = {
  transKey: string;
  components: Record<string, ReactElement>;
  replace?: Replace;
};

const Trans: FC<TransProps> = ({ transKey, components, replace }) => {
  const [ns, path] = lang.parseKey(transKey);

  return (
    <BaseTrans
      ns={ns}
      i18nKey={path}
      components={components}
      values={replace}
    />
  );
};

export default Trans;
