import React, { FC } from 'react';
import cx from 'classnames';

import { Title as BaseTitle } from 'components/ui';
import { TitleProps } from 'types/components';

import styles from './styles.module.css';

const Title: FC<TitleProps> = ({
  className,
  level = 4,
  ...props
}) => (
  <BaseTitle
    className={cx(styles.title, className)}
    level={level}
    {...props}
  />
);

export default Title;
