import { axios } from 'data';
import { Uuid } from 'types/common';
import { AuditLog } from 'types/models';
import { AuditLogsParams, AuditLogsResponse } from 'types/services';

import { preparePaginatableParams, preparePaginatedData, prepareTimezonableParams } from './helpers';

const getLogs = async (params: AuditLogsParams = {}) => {
  params = prepareTimezonableParams(params);
  params = preparePaginatableParams(params);

  const response = await axios.get<AuditLogsResponse>('/payments/admin/audit', { params });

  return preparePaginatedData<AuditLog>(response.data);
};

const getLog = async (id: Uuid) => {
  const response = await axios.get<AuditLog>(`/payments/admin/audit/${id}`);

  return response.data;
};

const auditLogService = {
  getLogs,
  getLog,
};

export default auditLogService;
