import React, { FC, ReactNode } from 'react';

import { Override } from 'types/common';
import { ButtonProps } from 'types/components';

import Button from '../Button';
import Tooltip from '../Tooltip';

type ActionButtonProps = Override<ButtonProps, {
  title: string;
  icon: ReactNode;
}>;

const ActionButton: FC<ActionButtonProps> = ({
  title,
  type = 'default',
  size = 'middle',
  ...props
}) => (
  <Tooltip title={title}>
    <Button type={type} size={size} {...props} />
  </Tooltip>
);

export default ActionButton;
