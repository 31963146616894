import React, { FC } from 'react';

import { useLang } from 'hooks';
import { useAllCountriesQuery } from 'hooks/queries';
import { Select } from 'components/ui';
import { SelectProps } from 'types/components';

type CountrySelectProps = Omit<SelectProps, 'options' | 'loading'>;

const CountrySelect: FC<CountrySelectProps> = ({
  showSearch = true,
  ...props
}) => {
  const lang = useLang();

  const countriesQuery = useAllCountriesQuery();

  return (
    <Select
      placeholder={lang.get('common.form.country.placeholder')}
      options={countriesQuery.data?.map((country) => ({
        value: country.code,
        label: country.name,
      }))}
      loading={countriesQuery.isFetching}
      showSearch={showSearch}
      {...props}
    />
  );
};

export default CountrySelect;
