import { config } from 'data';
import { auditLogService } from 'services';
import { useQuery } from 'hooks';
import { Uuid } from 'types/common';

const useAuditLogQuery = (id?: Uuid) => useQuery({
  queryKey: [config.AUDIT_LOG_QUERY_KEY, id],
  queryFn: () => id ? auditLogService.getLog(id) : null,
});

export default useAuditLogQuery;
