import { config } from 'data';
import { userService } from 'services';
import { useQuery } from 'hooks';
import { UserRolesParams } from 'types/services';

const useUserRolesQuery = (params?: UserRolesParams) => useQuery({
  queryKey: [config.USER_ROLES_QUERY_KEY, params],
  queryFn: () => userService.getRoles(params),
});

export default useUserRolesQuery;
