import { config } from 'data';
import { userService } from 'services';
import { useQuery } from 'hooks';
import { Uuid } from 'types/common';

const useUserRoleQuery = (id?: Uuid) => useQuery({
  queryKey: [config.USER_ROLE_QUERY_KEY, id],
  queryFn: () => id ? userService.getRole(id) : null,
});

export default useUserRoleQuery;
