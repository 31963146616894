import { config } from 'data';
import { costService } from 'services';
import { useQuery } from 'hooks';
import { Uuid } from 'types/common';

const useCostQuery = (id?: Uuid) => useQuery({
  queryKey: [config.COST_QUERY_KEY, id],
  queryFn: () => id ? costService.getCost(id) : null,
});

export default useCostQuery;
