import React from 'react';

import { useLang } from 'hooks';
import { DashboardLayout } from 'layouts';
import { DailyRemittanceView } from 'components/layout';

const DailyRemittancePage = () => {
  const lang = useLang();

  return (
    <DashboardLayout title={lang.get('report.dailyRemittance.title')}>
      <DailyRemittanceView title={lang.get('report.dailyRemittance.title')} />
    </DashboardLayout>
  );
};

export default DailyRemittancePage;
