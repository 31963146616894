import React, { FC } from 'react';
import { Button as BaseButton } from 'antd';
import cx from 'classnames';

import { ButtonProps } from 'types/components';

import styles from './styles.module.css';

const Button: FC<ButtonProps> = ({
  className,
  type = 'primary',
  size = 'large',
  ...props
}) => (
  <BaseButton
    className={cx(styles.root, className)}
    type={type}
    size={size}
    {...props}
  />
);

export default Button;
