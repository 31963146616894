import React, { FC } from 'react';

import { config, yup } from 'data';
import { useForm, useLang } from 'hooks';
import { Form, Modal, TextArea } from 'components/ui';
import { ModalBaseProps } from 'types/components';
import { BusinessApplication } from 'types/models';
import { BusinessApplicationRejectParams } from 'types/services';

type FormValues = BusinessApplicationRejectParams;

const initialValues: FormValues = {
  rejectReason: '',
};

const validationSchema = yup.object({
  rejectReason: yup.string().required().trim().max(config.STRING_MAX_LENGTH),
});

type RejectBusinessApplicationModalProps = ModalBaseProps & {
  businessApplication: BusinessApplication;
  loading?: boolean;
  onSubmit: (values: FormValues) => Promise<void>;
};

const RejectBusinessApplicationModal: FC<RejectBusinessApplicationModalProps> = ({
  businessApplication,
  open,
  loading,
  onClose,
  onSubmit,
}) => {
  const form = useForm<FormValues>();
  const lang = useLang();

  const handleSubmit = async (values: FormValues) => {
    await onSubmit(values);

    onClose();
  };

  return (
    <Modal
      title={lang.get('businessApplication.rejectModal.title', { name: businessApplication.name })}
      caption={lang.get('businessApplication.rejectModal.caption')}
      okText={lang.get('common.actions.reject')}
      okButtonProps={{ danger: true }}
      width="small"
      open={open}
      confirmLoading={loading}
      onOk={form.submit}
      onCancel={onClose}
    >
      <Form
        form={form}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onFinish={handleSubmit}
      >
        <Form.Item name="rejectReason" label={lang.get('businessApplication.rejectModal.reason.label')}>
          <TextArea placeholder={lang.get('businessApplication.rejectModal.reason.placeholder')} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default RejectBusinessApplicationModal;
