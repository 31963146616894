import { config } from 'data';
import { feeCommissionService } from 'services';
import { useQuery } from 'hooks';
import { Uuid } from 'types/common';

const useFeeCommissionQuery = (id?: Uuid) => useQuery({
  queryKey: [config.FEE_COMMISSION_QUERY_KEY, id],
  queryFn: () => id ? feeCommissionService.getFeeCommission(id) : null,
});

export default useFeeCommissionQuery;
