import React, { FC } from 'react';
import cx from 'classnames';

import { FlexProps } from 'types/components';

import Flex from '../Flex';

import styles from './styles.module.css';

const Actions: FC<FlexProps> = ({
  className,
  children,
  gap = 'small',
  ...props
}) => (
  <Flex
    className={cx(styles.actions, className)}
    gap={gap}
    {...props}
  >
    {children}
  </Flex>
);

export default Actions;
