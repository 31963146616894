import React, { FC } from 'react';

import { config, yup } from 'data';
import { file } from 'helpers';
import { transactionBundleService } from 'services';
import { useForm, useFormWatch, useLang, useMutation, useQueryInvalidate } from 'hooks';
import { useBusinessAccountQuery } from 'hooks/queries';
import { BusinessAccountSelect } from 'components/layout';
import { Form, Input, Modal } from 'components/ui';
import { Nullable } from 'types/common';
import { ModalBaseProps, UploadFileLike } from 'types/components';
import { BundleCreateParams } from 'types/services';

type FormValues = Omit<BundleCreateParams, 'fileId'> & {
  uploadFiles?: Nullable<UploadFileLike[]>;
};

const initialValues: FormValues = {
  clientId: '',
};

const validationSchema = yup.object({
  clientId: yup.string().required().uuid(),
  uploadFiles: yup.array().required().length(1).of(
    yup.mixed<UploadFileLike>().required().file().fileExtension(config.TRANSACTION_BUNDLE_ALLOWED_EXTENSIONS),
  ),
});

const CreateBundleModal: FC<ModalBaseProps> = ({ open, onClose }) => {
  const form = useForm<FormValues>();
  const lang = useLang();
  const queryInvalidate = useQueryInvalidate();

  const currentClientId = useFormWatch('clientId', form);

  const businessAccountQuery = useBusinessAccountQuery(currentClientId);

  const createBundleMutation = useMutation({
    mutationFn: async (values: FormValues) => {
      const fileIds = await file.handleFilesUpload(values.uploadFiles ?? []);

      delete values.uploadFiles;

      return transactionBundleService.createBundle({
        ...values,
        fileId: fileIds[0],
      });
    },
    onSuccess: () => queryInvalidate([config.TRANSACTION_BUNDLES_QUERY_KEY]),
    successNotification: lang.get('transactionBundle.modal.createSuccess'),
  });

  const handleSubmit = async (values: FormValues) => {
    await createBundleMutation.mutateAsync(values);

    onClose();
  };

  const currentBusinessAccount = businessAccountQuery.data;

  return (
    <Modal
      title={
        currentBusinessAccount
          ? lang.get('transactionBundle.modal.businessAccountTitle', { name: currentBusinessAccount.name })
          : lang.get('transactionBundle.modal.title')
      }
      caption={lang.get('transactionBundle.modal.createCaption')}
      okText={lang.get('common.actions.upload')}
      width="small"
      open={open}
      confirmLoading={createBundleMutation.isPending}
      onOk={form.submit}
      onCancel={onClose}
    >
      <Form
        form={form}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onFinish={handleSubmit}
      >
        <Form.Item name="clientId" label={lang.get('transactionBundle.modal.businessAccount.label')}>
          <BusinessAccountSelect placeholder={lang.get('transactionBundle.modal.businessAccount.placeholder')} />
        </Form.Item>
        <Form.UploadItem
          name="uploadFiles"
          label={`${lang.get('transactionBundle.modal.file.label')} (${config.TRANSACTION_BUNDLE_ALLOWED_EXTENSIONS.join(', ')})`}
        >
          <Input.Upload extensions={config.TRANSACTION_BUNDLE_ALLOWED_EXTENSIONS} />
        </Form.UploadItem>
      </Form>
    </Modal>
  );
};

export default CreateBundleModal;
