import { axios } from 'data';
import { CurrencyCode, CurrencyRate } from 'types/models';

import {
  CurrenciesParams,
  CurrenciesResponse,
  CurrenciesUpdateParams,
  CurrencyRateParams,
  CurrencyRatesParams,
  CurrencyRatesResponse,
} from 'types/services';

import { preparePaginatableParams, preparePaginatedData, prepareTimezonableParams } from './helpers';

const getCurrencies = async (params: CurrenciesParams = {}) => {
  const response = await axios.get<CurrenciesResponse>('/payments/admin/currency', { params });

  return response.data.list;
};

const updateCurrencies = async (codes: CurrencyCode[]) => {
  const params: CurrenciesUpdateParams = { currencies: codes };

  await axios.put<never>('/payments/admin/currency', params);
};

const getCurrencyRates = async (params: CurrencyRatesParams = {}) => {
  params = prepareTimezonableParams(params);
  params = preparePaginatableParams(params);

  const response = await axios.get<CurrencyRatesResponse>('/payments/admin/currency/rate', { params });

  return preparePaginatedData<CurrencyRate>(response.data);
};

const createCurrencyRate = async (params: CurrencyRateParams) => {
  await axios.post<never>('/payments/admin/currency/rate', params);
};

const currencyService = {
  getCurrencies,
  updateCurrencies,
  getCurrencyRates,
  createCurrencyRate,
};

export default currencyService;
