import { config } from 'data';
import { userService } from 'services';
import { fetchPaginatedResponseFully } from 'services/helpers';
import { useQuery } from 'hooks';

const useAllUserTopicsQuery = () => useQuery({
  queryKey: [config.USER_TOPICS_QUERY_KEY, 'all'],
  queryFn: () => fetchPaginatedResponseFully(userService.getTopics),
  refetchOnMount: false,
  refetchOnWindowFocus: false,
});

export default useAllUserTopicsQuery;
