import React, { useCallback, useMemo, useState } from 'react';

import { config } from 'data';
import { formatter } from 'helpers';
import { costService } from 'services';
import { fetchPaginatedResponseFully } from 'services/helpers';
import { useLang, useQueryModal, useTable, useTableQuery } from 'hooks';
import { useAllCountriesQuery, useCostQuery, useCostsQuery } from 'hooks/queries';
import { Plus } from 'components/icons';
import { ActivityStatus, Can, CountrySelect, CurrencySelect, ProviderSelect, TableView } from 'components/layout';
import { Button, Flex, Form, Select, Table } from 'components/ui';
import { Uuid } from 'types/common';
import { ExportColumns, TableColumns } from 'types/components';
import { CostsParams } from 'types/services';

import {
  ActivityStatus as TActivityStatus,
  Cost,
  CurrencyCode,
  TransactionFeeType,
  UserPermission,
} from 'types/models';

import styles from './styles.module.css';

import Modal from './Modal';

const EXPORT_FILE_NAME = 'cost-configurations';

type TableParams = {
  providerId?: Uuid;
  country?: string;
  currency?: CurrencyCode;
  status?: TActivityStatus;
};

const initialTableParams: TableParams = {
  //
};

const CostsView = () => {
  const lang = useLang();
  const table = useTable<Cost, TableParams>([config.COSTS_QUERY_KEY], initialTableParams);

  const [costId, setCostId] = useState<Uuid>();

  const modal = useQueryModal(config.COSTS_QUERY_KEY, setCostId);

  const costsParams: CostsParams = {
    page: table.page,
    providerId: table.params.providerId || undefined,
    country: table.params.country || undefined,
    currency: table.params.currency || undefined,
    active: table.params.status ? table.params.status === TActivityStatus.ACTIVE : undefined,
  };

  const costsQuery = useCostsQuery(costsParams);
  const costQuery = useCostQuery(costId);
  const countriesQuery = useAllCountriesQuery();

  const handleCreateClick = () => {
    modal.show();
  };

  const handleViewClick = (cost: Cost) => () => {
    modal.show(cost.id);
  };

  useTableQuery(table, costsQuery);

  const formatCostProvider = (cost: Cost) => cost.provider.name;

  const formatCostCountry = useCallback((cost: Cost) => {
    return cost.country
      ? formatter.formatCountry(countriesQuery.data ?? [], cost.country)
      : lang.get('common.actions.any');
  }, [lang, countriesQuery.data]);

  const formatCostCurrency = useCallback((cost: Cost) => cost.currency ?? lang.get('common.actions.any'), [lang]);

  const formatCostTransactionFee = (cost: Cost) => {
    if (cost.transactionFeeType === TransactionFeeType.PERCENT) {
      return formatter.formatPercent(cost.transactionFee);
    }

    return formatter.formatCurrency(cost.transactionFee, cost.provider.currency);
  };

  const formatCostFxMarkup = (cost: Cost) => formatter.formatPercent(cost.fxMarkup);

  const formatCostMinTransactionFeeAmount = (cost: Cost) => {
    if (cost.transactionFeeType === TransactionFeeType.PERCENT) {
      return formatter.formatCurrency(cost.minTransactionFeeAmount, cost.provider.currency);
    }

    return '-';
  };

  const formatCostMaxTransactionFeeAmount = (cost: Cost) => {
    if (cost.transactionFeeType === TransactionFeeType.PERCENT) {
      return formatter.formatCurrency(cost.maxTransactionFeeAmount, cost.provider.currency);
    }

    return '-';
  };

  const exportColumns: ExportColumns<Cost> = useMemo(() => [
    {
      title: lang.get('cost.list.provider'),
      render: (cost) => formatCostProvider(cost),
    }, {
      title: lang.get('cost.list.country'),
      render: (cost) => formatCostCountry(cost),
    }, {
      title: lang.get('cost.list.currency'),
      render: (cost) => formatCostCurrency(cost),
    }, {
      title: lang.get('cost.list.transactionFee'),
      render: (cost) => formatCostTransactionFee(cost),
    }, {
      title: lang.get('cost.list.fxMarkup'),
      render: (cost) => formatCostFxMarkup(cost),
    }, {
      title: lang.get('cost.list.minTransactionFeeAmount'),
      render: (cost) => formatCostMinTransactionFeeAmount(cost),
    }, {
      title: lang.get('cost.list.maxTransactionFeeAmount'),
      render: (cost) => formatCostMaxTransactionFeeAmount(cost),
    }, {
      title: lang.get('cost.list.startDate'),
      render: (cost) => cost.startDate ? formatter.formatDate(cost.startDate) : '-',
    }, {
      title: lang.get('cost.list.endDate'),
      render: (cost) => cost.endDate ? formatter.formatDate(cost.endDate) : '-',
    }, {
      title: lang.get('cost.list.status'),
      render: (cost) => lang.get(cost.active ? 'common.activityStatuses.active' : 'common.activityStatuses.inactive'),
    },
  ], [lang, formatCostCountry, formatCostCurrency]);

  const tableColumns: TableColumns<Cost> = [
    {
      className: styles.table__provider,
      key: 'provider',
      title: lang.get('cost.list.provider'),
      render: (_, cost) => <Table.Truncate>{formatCostProvider(cost)}</Table.Truncate>,
    }, {
      key: 'country',
      title: lang.get('cost.list.country'),
      render: (_, cost) => formatCostCountry(cost),
    }, {
      key: 'currency',
      title: lang.get('cost.list.currency'),
      render: (_, cost) => formatCostCurrency(cost),
    }, {
      className: styles.table__amount,
      key: 'transactionFee',
      title: lang.get('cost.list.transactionFee'),
      render: (_, cost) => formatCostTransactionFee(cost),
    }, {
      className: styles.table__amount,
      key: 'fxMarkup',
      title: lang.get('cost.list.fxMarkup'),
      render: (_, cost) => formatCostFxMarkup(cost),
    }, {
      className: styles.table__amount,
      key: 'minTransactionFeeAmount',
      title: lang.get('cost.list.minTransactionFeeAmount'),
      render: (_, cost) => formatCostMinTransactionFeeAmount(cost),
    }, {
      className: styles.table__amount,
      key: 'maxTransactionFeeAmount',
      title: lang.get('cost.list.maxTransactionFeeAmount'),
      render: (_, cost) => formatCostMaxTransactionFeeAmount(cost),
    }, {
      key: 'status',
      title: lang.get('cost.list.status'),
      render: (_, cost) => <ActivityStatus active={cost.active} />,
    },
  ];

  return (
    <Can permissions={[UserPermission.COSTS_VIEW, UserPermission.COSTS_UPDATE]}>
      <TableView
        title={lang.get('cost.list.title')}
        actions={(
          <Flex gap="small" wrap="wrap">
            <TableView.ExportButton<Cost>
              table={table}
              type="default"
              fileName={EXPORT_FILE_NAME}
              columns={exportColumns}
              fetchData={() => fetchPaginatedResponseFully(costService.getCosts, costsParams)}
            />
            <Can permissions={[UserPermission.COSTS_UPDATE]}>
              <Button icon={<Plus />} onClick={handleCreateClick}>
                {lang.get('cost.list.create')}
              </Button>
            </Can>
          </Flex>
        )}
      >

        <TableView.Filters<TableParams>
          initialValues={initialTableParams}
          values={table.params}
          onSubmit={table.setParams}
        >
          <Form.Item name="providerId" label={lang.get('common.filters.provider')}>
            <ProviderSelect placeholder={lang.get('common.actions.all')} allowClear />
          </Form.Item>
          <Form.Item name="country" label={lang.get('common.filters.country')}>
            <CountrySelect placeholder={lang.get('common.actions.all')} allowClear />
          </Form.Item>
          <Form.Item name="currency" label={lang.get('common.filters.currency')}>
            <CurrencySelect placeholder={lang.get('common.actions.all')} allowClear />
          </Form.Item>
          <Form.Item name="status" label={lang.get('common.filters.status')}>
            <Select
              placeholder={lang.get('common.actions.all')}
              options={Object.values(TActivityStatus).map((status) => ({
                value: status,
                label: lang.get(`common.activityStatuses.${status.toLowerCase()}`),
              }))}
              allowClear
            />
          </Form.Item>
        </TableView.Filters>

        <TableView.Table<Cost>
          table={table}
          columns={tableColumns}
          rowKey={(cost) => cost.id}
          loading={costsQuery.isFetching}
          clickable
          onRow={(cost) => ({ onClick: handleViewClick(cost) })}
        />

        <Modal
          cost={costQuery.data || undefined}
          open={!costQuery.isFetching && modal.open}
          onClose={modal.hide}
        />

      </TableView>
    </Can>
  );
};

export default CostsView;
