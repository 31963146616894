import React from 'react';
import cx from 'classnames';

import { useLang } from 'hooks';
import { Flex, Text } from 'components/ui';
import { Override } from 'types/common';
import { FlexProps } from 'types/components';
import { TableInstance } from 'types/hooks';

import styles from './styles.module.css';

type ActionsProps<DataType> = Override<FlexProps, {
  table: TableInstance<DataType>;
}>;

const Actions = <DataType extends object>({
  className,
  gap = 'small',
  align = 'center',
  wrap = 'wrap',
  table,
  children,
  ...props
}: ActionsProps<DataType>) => {
  const lang = useLang();

  return (
    <Flex
      className={cx(styles.actions, className)}
      gap={gap}
      align={align}
      wrap={wrap}
      {...props}
    >
      {children}
      <Text
        className={styles.actions__caption}
        type="secondary"
        hidden={!table.selectedData.length}
      >
        {lang.choice('common.list.selectedRows', table.selectedData.length)}
      </Text>
    </Flex>
  );
};

export default Actions;
