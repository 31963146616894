import React from 'react';

import { xlsx } from 'helpers';
import { useLang, useModal } from 'hooks';
import { Table as TableIcon } from 'components/icons';
import { Button } from 'components/ui';
import { XlsxHeader, XlsxRow } from 'types/common';
import { ExportButtonProps, ExportColumns } from 'types/components';

import Modal from './Modal';

const ExportButton = <DataType extends object>({
  component: CustomButton,
  fileName,
  columns,
  icon = <TableIcon />,
  fetchData,
  onStart,
  onEnd,
  onFinish,
  ...props
}: ExportButtonProps<DataType>) => {
  const lang = useLang();
  const modal = useModal();

  const handleExport = async (columns: ExportColumns<DataType>) => {
    try {
      onStart?.();

      const data = await fetchData();

      const headers: XlsxHeader[] = columns.map((column) => column.title);
      const rows: XlsxRow[] = data.map((item) => columns.map((column) => column.render(item)));

      xlsx.exportFile(fileName, headers, rows);

      onEnd?.();
    } finally {
      onFinish?.();
    }
  };

  const Component = CustomButton ?? Button;

  return (
    <>

      <Component icon={icon} onClick={modal.show} {...props}>
        {lang.get('common.export.button')}
      </Component>

      <Modal<DataType>
        columns={columns}
        open={modal.open}
        onSubmit={handleExport}
        onClose={modal.hide}
      />

    </>
  );
};

export default ExportButton;
