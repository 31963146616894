import React from 'react';

import { ExportButtonProps as BaseExportButtonProps } from 'types/components';
import { TableInstance } from 'types/hooks';

import BaseExportButton from '../ExportButton';

type ExportButtonProps<DataType> = Omit<
  BaseExportButtonProps<DataType>,
  'disabled' | 'loading' | 'onStart' | 'onFinish'
> & {
  table: TableInstance<DataType>;
};

const ExportButton = <DataType extends object>({ table, ...props }: ExportButtonProps<DataType>) => (
  <BaseExportButton
    {...props}
    disabled={!table.data.length}
    loading={table.exporting}
    onStart={() => table.setExporting(true)}
    onFinish={() => table.setExporting(false)}
  />
);

export default ExportButton;
