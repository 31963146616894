import React, { ChangeEventHandler, FC } from 'react';

import { useLang } from 'hooks';
import { Search as SearchIcon } from 'components/icons';
import { Input } from 'components/ui';
import { InputProps } from 'types/components';

const Search: FC<InputProps> = ({
  type = 'search',
  placeholder,
  prefix = <SearchIcon />,
  onChange,
  ...props
}) => {
  const lang = useLang();

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    event.target.value = event.target.value.trim();

    onChange?.(event);
  };

  return (
    <Input
      type={type}
      placeholder={placeholder ?? lang.get('common.actions.search')}
      prefix={prefix}
      allowClear
      onChange={handleChange}
      {...props}
    />
  );
};

export default Search;
