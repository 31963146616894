import { axios } from 'data';
import { Uuid } from 'types/common';
import { TransactionBundle, TransactionBundleExtended, TransactionBundleStatus } from 'types/models';
import { BundleApproveParams, BundleCreateParams, BundlesParams, BundlesResponse } from 'types/services';

import { preparePaginatableParams, preparePaginatedData, prepareTimezonableParams } from './helpers';

const getBundles = async (params: BundlesParams = {}) => {
  params = prepareTimezonableParams(params);
  params = preparePaginatableParams(params);

  const response = await axios.get<BundlesResponse>('/payments/admin/bundle', { params });

  return preparePaginatedData<TransactionBundle>(response.data);
};

const getBundle = async (id: Uuid) => {
  const response = await axios.get<TransactionBundleExtended>(`/payments/admin/bundle/${id}`);

  return response.data;
};

const createBundle = async (params: BundleCreateParams) => {
  await axios.post<never>('/payments/admin/bundle', params);
};

const approveBundle = async (id: Uuid, params: BundleApproveParams) => {
  await axios.put<never>(`/payments/admin/bundle/${id}/status`, {
    ...params,
    status: TransactionBundleStatus.APPROVED,
  });
};

const rejectBundle = async (id: Uuid) => {
  await axios.put<never>(`/payments/admin/bundle/${id}/status`, {
    status: TransactionBundleStatus.REJECTED,
  });
};

const transactionBundleService = {
  getBundles,
  getBundle,
  createBundle,
  approveBundle,
  rejectBundle,
};

export default transactionBundleService;
