import React, { FC } from 'react';
import { Typography } from 'antd';

import { TitleProps } from 'types/components';

const Title: FC<TitleProps> = (props) => (
  <Typography.Title {...props} />
);

export default Title;
