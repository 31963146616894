import { i18next } from 'data';
import { Replace } from 'types/locales';

const parseKey = (key: string) => {
  const [ns, ...paths] = key.split('.');

  return [ns, paths.join('.')];
};

const has = (key: string) => {
  const [ns, path] = parseKey(key);

  return i18next.exists(path, { ns });
};

const get = (key: string, replace?: Replace) => {
  const [ns, path] = parseKey(key);

  return i18next.t(path, { ns, replace });
};

const choice = (key: string, count: number, replace?: Replace) => {
  const [ns, path] = parseKey(key);

  return i18next.t(path, { ns, count, replace });
};

const lang = {
  parseKey,
  has,
  get,
  choice,
};

export default lang;
