import React from 'react';
import { useNavigate } from 'react-router-dom';

import { url } from 'helpers';
import { useLang } from 'hooks';
import { useProvidersQuery } from 'hooks/queries';
import { Can, CardView } from 'components/layout';
import { Button } from 'components/ui';
import { UserPermission } from 'types/models';

const ProvidersView = () => {
  const lang = useLang();
  const navigate = useNavigate();

  const activeProvidersQuery = useProvidersQuery({ active: true });

  return (
    <Can permissions={[UserPermission.PROVIDERS_VIEW, UserPermission.PROVIDERS_UPDATE]}>
      <CardView
        title={lang.get('provider.list.title')}
        caption={lang.choice('provider.list.caption', activeProvidersQuery.data?.total ?? 0)}
        actions={(
          <Button
            color="primary"
            variant="filled"
            onClick={() => navigate(url.toProviders())}
          >
            {lang.get('common.actions.view')}
          </Button>
        )}
      />
    </Can>
  );
};

export default ProvidersView;
