import React, { FC } from 'react';

import { FormItemProps } from 'types/components';

import Item from './Item';

const SwitchItem: FC<FormItemProps> = ({
  layout = 'horizontal',
  valuePropName = 'checked',
  ...props
}) => (
  <Item
    layout={layout}
    valuePropName={valuePropName}
    {...props}
  />
);

export default SwitchItem;
