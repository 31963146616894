import React, { FC } from 'react';
import { PresetStatusColorType } from 'antd/es/_util/colors';

import { formatter } from 'helpers';
import { useLang } from 'hooks';
import { BusinessApplicationStatus } from 'components/layout';
import { Alert, Descriptions, Form, Link, Modal, Tag } from 'components/ui';
import { ModalBaseProps } from 'types/components';
import { BusinessApplication, BusinessApplicationKybStatus } from 'types/models';

import Actions from './Actions';

const kybStatusColors: Record<BusinessApplicationKybStatus, PresetStatusColorType> = {
  [BusinessApplicationKybStatus.AWAITING_DOCUMENTS]: 'processing',
  [BusinessApplicationKybStatus.AWAITING_QUESTIONARY]: 'processing',
  [BusinessApplicationKybStatus.DOCUMENTS_UPLOADED]: 'processing',
  [BusinessApplicationKybStatus.FAILED]: 'error',
  [BusinessApplicationKybStatus.NEW]: 'warning',
  [BusinessApplicationKybStatus.PASSED]: 'success',
  [BusinessApplicationKybStatus.REVIEW]: 'warning',
  [BusinessApplicationKybStatus.REJECTED]: 'error',
  [BusinessApplicationKybStatus.QUESTIONARY_PASSED]: 'processing',
};

type ViewBusinessApplicationModalProps = ModalBaseProps & {
  businessApplication: BusinessApplication;
};

const ViewBusinessApplicationModal: FC<ViewBusinessApplicationModalProps> = ({
  businessApplication,
  open,
  onClose,
}) => {
  const lang = useLang();

  const kyb = businessApplication.kybDetails;

  return (
    <Modal
      title={lang.get('businessApplication.modal.viewTitle', { name: businessApplication.name })}
      caption={lang.get('businessApplication.modal.viewCaption')}
      cancelText={lang.get('common.actions.close')}
      okButtonProps={{ hidden: true }}
      open={open}
      onCancel={onClose}
    >

      <Actions businessApplication={businessApplication} onSubmit={onClose} />

      <Descriptions
        items={[{
          children: <Alert type="error" message={businessApplication.rejectReason} />,
          span: 2,
          hidden: !businessApplication.rejectReason,
        }, {
          label: lang.get('common.form.name.label'),
          children: businessApplication.name,
        }, {
          label: lang.get('common.form.email.label'),
          children: <Link href={`mailto:${businessApplication.email}`}>{businessApplication.email}</Link>,
        }, {
          label: lang.get('businessApplication.modal.industry.label'),
          children: businessApplication.mainIndustry?.value ?? '-',
        }, {
          label: lang.get('businessApplication.modal.activity.label'),
          children: businessApplication.activity,
        }, {
          label: lang.get('common.form.creationDate.label'),
          children: formatter.formatDateTime(businessApplication.createdAt),
        }, {
          label: lang.get('common.form.status.label'),
          children: <BusinessApplicationStatus status={businessApplication.status} />,
        }]}
      />

      <Form.Divider hidden={!kyb} />

      {kyb && (
        <Descriptions
          items={[{
            label: lang.get('common.form.verificationDetails.label'),
            children: kyb.detailsLink
              ? <Link href={kyb.detailsLink}>{lang.get('common.actions.view')}</Link>
              : '-',
          }, {
            label: lang.get('common.form.verificationStatus.label'),
            children: (
              <Tag color={kybStatusColors[kyb.status]}>
                {lang.get(`businessApplication.kybStatuses.${kyb.status.toLowerCase()}`)}
              </Tag>
            ),
          }]}
        />
      )}

    </Modal>
  );
};

export default ViewBusinessApplicationModal;
