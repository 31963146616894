import React, { FC } from 'react';
import { Descriptions as BaseDescriptions, DescriptionsProps as BaseDescriptionsProps } from 'antd';
import { DescriptionsItemProps } from 'antd/es/descriptions/Item';
import cx from 'classnames';

import { Override } from 'types/common';

import styles from './styles.module.css';

type DescriptionsProps = Override<BaseDescriptionsProps, {
  items: Array<DescriptionsItemProps & {
    hidden?: boolean;
  }>;
}>;

const Descriptions: FC<DescriptionsProps> = ({
  className,
  items,
  column = 2,
  colon = false,
  ...props
}) => (
  <BaseDescriptions
    className={cx(styles.root, className)}
    items={items.filter((item) => !item.hidden)}
    column={column}
    colon={colon}
    {...props}
  />
);

export default Descriptions;
