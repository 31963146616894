import React, { FC } from 'react';
import { PresetStatusColorType } from 'antd/es/_util/colors';

import { useLang } from 'hooks';
import { Tag } from 'components/ui';
import { Override } from 'types/common';
import { TagProps } from 'types/components';
import { TransactionBundleStatus as ETransactionBundleStatus } from 'types/models';

const statusColors: Record<ETransactionBundleStatus, PresetStatusColorType> = {
  [ETransactionBundleStatus.APPROVED]: 'processing',
  [ETransactionBundleStatus.COMPLETED]: 'success',
  [ETransactionBundleStatus.FAILED]: 'error',
  [ETransactionBundleStatus.IN_PROGRESS]: 'processing',
  [ETransactionBundleStatus.NEW]: 'warning',
  [ETransactionBundleStatus.REJECTED]: 'default',
  [ETransactionBundleStatus.VALIDATION_FAILED]: 'error',
};

type TransactionBundleStatusProps = Override<Omit<TagProps, 'color' | 'children'>, {
  status: ETransactionBundleStatus;
}>;

const TransactionBundleStatus: FC<TransactionBundleStatusProps> = ({ status, ...props }) => {
  const lang = useLang();

  return (
    <Tag color={statusColors[status]} {...props}>
      {lang.get(`transactionBundle.statuses.${status.toLowerCase()}`)}
    </Tag>
  );
};

export default TransactionBundleStatus;
