import React, { useEffect, useState } from 'react';

import { useLang } from 'hooks';
import { Checkbox, Flex, Form, Link, Modal, Space } from 'components/ui';
import { ExportColumns, ModalBaseProps } from 'types/components';

type ExportModalProps<DataType> = ModalBaseProps & {
  columns: ExportColumns<DataType>;
  onSubmit: (columns: ExportColumns<DataType>) => Promise<void>;
};

const ExportModal = <DataType extends object>({
  columns,
  open,
  onSubmit,
  onClose,
}: ExportModalProps<DataType>) => {
  const lang = useLang();

  const [selectedColumns, setSelectedColumns] = useState<ExportColumns<DataType>>([]);
  const [submitting, setSubmitting] = useState(false);

  const handleSelectAllClick = () => handleChange(columns);

  const handleCleanAllClick = () => handleChange([]);

  const handleChange = setSelectedColumns;

  const handleSubmit = async () => {
    try {
      setSubmitting(true);

      await onSubmit(
        // Preserve columns order
        columns.filter((column) => selectedColumns.includes(column)),
      );

      onClose();
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (open) {
      setSelectedColumns(columns);
    }
  }, [columns, open]);

  return (
    <Modal
      title={lang.get('common.export.modal.title')}
      caption={lang.choice('common.export.modal.caption', selectedColumns.length)}
      okText={lang.get('common.actions.export')}
      okButtonProps={{ disabled: !selectedColumns.length }}
      width="small"
      open={open}
      confirmLoading={submitting}
      onOk={handleSubmit}
      onCancel={onClose}
    >
      <Form>
        <Form.Item>
          <Flex gap="small" justify="space-between" wrap="wrap">
            <Link onClick={handleSelectAllClick}>
              <strong>{lang.get('common.actions.selectAll')}</strong>
            </Link>
            <Link onClick={handleCleanAllClick}>
              <strong>{lang.get('common.actions.cleanAll')}</strong>
            </Link>
          </Flex>
        </Form.Item>
        <Form.Item>
          <Checkbox.Group value={selectedColumns} onChange={handleChange}>
            <Space direction="vertical">
              {columns.map((column, index) => (
                <Checkbox key={index} value={column}>
                  {column.title}
                </Checkbox>
              ))}
            </Space>
          </Checkbox.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ExportModal;
