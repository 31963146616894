import { config } from 'data';
import { transactionService } from 'services';
import { useQuery } from 'hooks';
import { Uuid } from 'types/common';

const useTransactionQuery = (id?: Uuid) => useQuery({
  queryKey: [config.TRANSACTION_QUERY_KEY, id],
  queryFn: () => id ? transactionService.getTransaction(id) : null,
});

export default useTransactionQuery;
