import React, { FC } from 'react';

import { useLang } from 'hooks';
import { Tag } from 'components/ui';
import { Override } from 'types/common';
import { TagProps } from 'types/components';
import { ClientStatus as EClientStatus } from 'types/models';

type ClientStatusProps = Override<Omit<TagProps, 'color' | 'children'>, {
  status: EClientStatus;
}>;

const ClientStatus: FC<ClientStatusProps> = ({ status, ...props }) => {
  const lang = useLang();

  return (
    <Tag color={status === EClientStatus.ACTIVE ? 'success' : 'default'} {...props}>
      {lang.get(`client.statuses.${status.toLowerCase()}`)}
    </Tag>
  );
};

export default ClientStatus;
