import { config } from 'data';
import { reportService } from 'services';
import { useQuery } from 'hooks';
import { ReportBusinessAccountsBalancesParams } from 'types/services';

const useBusinessAccountsBalancesReportQuery = (params?: ReportBusinessAccountsBalancesParams) => useQuery({
  queryKey: [config.BUSINESS_ACCOUNTS_BALANCES_QUERY_KEY, params],
  queryFn: () => reportService.getBusinessAccountsBalances(params),
});

export default useBusinessAccountsBalancesReportQuery;
