import React, { FC, useEffect } from 'react';
import map from 'lodash/map';

import { config, yup } from 'data';
import { profileService } from 'services';
import { useForm, useLang, useMutation, useQueryInvalidate } from 'hooks';
import { useAllProfileTopicsQuery, useAllUserTopicsQuery } from 'hooks/queries';
import { Checkbox, Form, Modal, Space } from 'components/ui';
import { Uuid } from 'types/common';
import { ModalBaseProps } from 'types/components';

type FormValues = {
  ids: Uuid[];
};

const initialValues: FormValues = {
  ids: [],
};

const validationSchema = yup.object({
  ids: yup.array().required().of(
    yup.string().required().uuid(),
  ),
});

const NotificationsModal: FC<ModalBaseProps> = ({ open, onClose }) => {
  const form = useForm<FormValues>();
  const lang = useLang();
  const queryInvalidate = useQueryInvalidate();

  const topicsQuery = useAllUserTopicsQuery();
  const profileTopicsQuery = useAllProfileTopicsQuery();

  const updateTopicsMutation = useMutation({
    mutationFn: profileService.updateTopics,
    onSuccess: () => queryInvalidate([config.PROFILE_TOPICS_QUERY_KEY]),
    successNotification: lang.get('profile.notifications.success'),
  });

  const handleSubmit = async (values: FormValues) => {
    if (!topicsQuery.data) {
      return;
    }

    await updateTopicsMutation.mutateAsync(
      topicsQuery.data?.map((topic) => ({
        id: topic.id,
        subscribe: values.ids.includes(topic.id),
      })),
    );

    onClose();
  };

  useEffect(() => {
    if (open && profileTopicsQuery.data) {
      form.setFieldsValue({ ids: map(profileTopicsQuery.data, 'id') });
    }
  }, [open, form, profileTopicsQuery.data]);

  return (
    <Modal
      title={lang.get('profile.notifications.title')}
      caption={lang.get('profile.notifications.caption')}
      okText={lang.get('common.actions.save')}
      width="small"
      open={open}
      confirmLoading={updateTopicsMutation.isPending}
      onOk={form.submit}
      onCancel={onClose}
    >
      <Form
        form={form}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onFinish={handleSubmit}
      >
        <Form.Item name="ids">
          <Checkbox.Group>
            <Space direction="vertical">
              {topicsQuery.data?.map((topic) => (
                <Checkbox key={topic.id} value={topic.id}>
                  {topic.title}
                </Checkbox>
              ))}
            </Space>
          </Checkbox.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default NotificationsModal;
