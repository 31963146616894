import { config } from 'data';
import { businessAccountService } from 'services';
import { useQuery } from 'hooks';
import { Uuid } from 'types/common';

const useBusinessAccountDetailsQuery = (id?: Uuid) => useQuery({
  queryKey: [config.BUSINESS_ACCOUNT_DETAILS_QUERY_KEY, id],
  queryFn: () => id ? businessAccountService.getBusinessAccountDetails(id) : null,
});

export default useBusinessAccountDetailsQuery;
