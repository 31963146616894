import { useCallback, useMemo, useState } from 'react';

const useModal = () => {
  const [open, setOpen] = useState(false);

  const show = useCallback(() => setOpen(true), []);

  const hide = useCallback(() => setOpen(false), []);

  return useMemo(() => ({
    open,
    show,
    hide,
  }), [open, show, hide]);
};

export default useModal;
