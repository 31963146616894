import React, { useState } from 'react';

import { config } from 'data';
import { formatter } from 'helpers';
import { useLang, useQueryModal, useTable, useTableQuery } from 'hooks';
import { useFeeGroupQuery, useFeeGroupsQuery } from 'hooks/queries';
import { DashboardLayout } from 'layouts';
import { Plus } from 'components/icons';
import { Can, TableView } from 'components/layout';
import { Button, Table } from 'components/ui';
import { Uuid } from 'types/common';
import { TableColumns } from 'types/components';
import { FeeGroup, UserPermission } from 'types/models';

import styles from './styles.module.css';

import Modal from './Modal';

const FeeGroupsPage = () => {
  const lang = useLang();
  const table = useTable<FeeGroup>([config.FEE_GROUPS_QUERY_KEY]);

  const [feeGroupId, setFeeGroupId] = useState<Uuid>();

  const modal = useQueryModal(config.FEE_GROUPS_QUERY_KEY, setFeeGroupId);

  const feeGroupsQuery = useFeeGroupsQuery({ page: table.page });
  const feeGroupQuery = useFeeGroupQuery(feeGroupId);

  const handleCreateClick = () => {
    modal.show();
  };

  const handleViewClick = (feeGroup: FeeGroup) => () => {
    modal.show(feeGroup.id);
  };

  useTableQuery(table, feeGroupsQuery);

  const columns: TableColumns<FeeGroup> = [
    {
      className: styles.table__name,
      key: 'name',
      title: lang.get('feeGroup.list.name'),
      render: (_, feeGroup) => <Table.Truncate>{feeGroup.name}</Table.Truncate>,
    }, {
      className: styles.table__date,
      key: 'date',
      title: lang.get('feeGroup.list.date'),
      render: (_, feeGroup) => formatter.formatDateTime(feeGroup.updatedAt ?? feeGroup.createdAt),
    },
  ];

  return (
    <DashboardLayout title={lang.get('feeGroup.list.title')}>
      <TableView
        title={lang.get('feeGroup.list.title')}
        actions={(
          <Can permissions={[UserPermission.FEE_GROUPS_UPDATE]}>
            <Button icon={<Plus />} onClick={handleCreateClick}>
              {lang.get('feeGroup.list.create')}
            </Button>
          </Can>
        )}
      >

        <TableView.Table<FeeGroup>
          table={table}
          columns={columns}
          rowKey={(feeGroup) => feeGroup.id}
          loading={feeGroupsQuery.isFetching}
          clickable
          onRow={(feeGroup) => ({ onClick: handleViewClick(feeGroup) })}
        />

        <Modal
          feeGroup={feeGroupQuery.data || undefined}
          open={!feeGroupQuery.isFetching && modal.open}
          onClose={modal.hide}
        />

      </TableView>
    </DashboardLayout>
  );
};

export default FeeGroupsPage;
