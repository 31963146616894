import { useCallback, useMemo } from 'react';
import { Form, FormInstance } from 'antd';
import { FieldData } from 'rc-field-form/es/interface';

import { moment } from 'data';

const useForm = <Values extends object>(instance?: FormInstance<Values>) => {
  const [form] = Form.useForm<Values>(instance);

  const prepareValues = useCallback((values: Values) => {
    const prepareValue = (value: unknown) => {
      if (Array.isArray(value)) {
        value = value.map(prepareValue);
      }

      if (moment.isMoment(value)) {
        value = value.toISOString();
      }

      return value;
    };

    return Object.fromEntries(
      Object.entries(values).map(([key, value]) => [key, prepareValue(value)]),
    ) as Values;
  }, []);

  const setFieldsValueWithoutTouching = useCallback((values: Partial<Values>) => {
    const fields = Object.entries(values).map<FieldData>(([name, value]) => ({
      name,
      value,
      touched: false,
    }));

    form.setFields(fields);
  }, [form]);

  const reset = useCallback((initialValues?: Partial<Values>) => {
    const values = form.getFieldsValue(true);
    const entries = Object.entries(values).map(([name]) => [name, initialValues?.[name as keyof Values]]);

    setFieldsValueWithoutTouching(Object.fromEntries(entries));
  }, [form, setFieldsValueWithoutTouching]);

  return useMemo(() => ({
    ...form,
    prepareValues,
    setFieldsValueWithoutTouching,
    reset,
  }), [form, prepareValues, setFieldsValueWithoutTouching, reset]);
};

export default useForm;
