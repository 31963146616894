import React, { FC } from 'react';
import cx from 'classnames';

import { useLang } from 'hooks';
import { Briefcase, User } from 'components/icons';
import { BusinessAccountType } from 'types/models';

import styles from './styles.module.css';

type BusinessAccountIconProps = {
  businessAccountType: BusinessAccountType;
  size?: 'middle' | 'large';
};

const BusinessAccountIcon: FC<BusinessAccountIconProps> = ({
  businessAccountType,
  size = 'middle',
}) => {
  const lang = useLang();

  return (
    <span
      className={cx(styles.root, styles[`root_${size}`])}
      title={lang.get(`businessAccount.types.${businessAccountType.toLowerCase()}`)}
    >
      {businessAccountType === BusinessAccountType.COMPANY ? <Briefcase /> : <User />}
    </span>
  );
};

export default BusinessAccountIcon;
