import React, { FC } from 'react';

import { IconProps } from 'types/components';

import Icon from './Icon';

const Filter: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path d="M5 10h10M2.5 5h15m-10 10h5" />
  </Icon>
);

export default Filter;
