import React from 'react';

import { useLang } from 'hooks';
import { DashboardLayout } from 'layouts';
import { SinglePage, TransactionsView } from 'components/layout';
import { Grid } from 'components/ui';
import { BusinessAccountType } from 'types/models';

import Members from './Members';
import View from './View';

const BusinessAccountPage = () => {
  const lang = useLang();

  return (
    <SinglePage.BusinessAccount render={(businessAccount) => (
      <DashboardLayout title={
        businessAccount.type === BusinessAccountType.INDIVIDUAL
          ? lang.get('businessAccount.single.individualTitle', { name: businessAccount.name })
          : lang.get('businessAccount.single.title', { name: businessAccount.name })
      }>
        <Grid.Row gutter={[8, 8]}>
          <Grid.Col span={24}>
            <View businessAccount={businessAccount} />
          </Grid.Col>
          <Grid.Col span={24} md={12}>
            {businessAccount.type !== BusinessAccountType.INDIVIDUAL && (
              <Members businessAccount={businessAccount} />
            )}
          </Grid.Col>
          <Grid.Col span={24} md={12} />
          <Grid.Col span={24}>
            <TransactionsView businessAccount={businessAccount} />
          </Grid.Col>
        </Grid.Row>
      </DashboardLayout>
    )} />
  );
};

export default BusinessAccountPage;
