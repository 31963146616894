import React, { useCallback, useMemo, useState } from 'react';

import { config } from 'data';
import { formatter } from 'helpers';
import { feeCommissionService, transactionService } from 'services';
import { fetchPaginatedResponseFully } from 'services/helpers';
import { useForm, useFormWatch, useLang, useQueryModal, useTable, useTableQuery } from 'hooks';
import { useAllCountriesQuery, useFeeCommissionQuery, useFeeCommissionsQuery } from 'hooks/queries';
import { Plus } from 'components/icons';
import { ActivityStatus, Can, CountrySelect, CurrencySelect, TableView } from 'components/layout';
import { Button, Flex, Form, Select, Table } from 'components/ui';
import { Uuid } from 'types/common';
import { ExportColumns, TableColumns } from 'types/components';
import { FeeCommissionsParams } from 'types/services';

import {
  CountryCode,
  CurrencyCode,
  FeeCommission,
  FeeCommissionLevel,
  TransactionDirection,
  TransactionType,
  UserPermission,
} from 'types/models';

import styles from './styles.module.css';

import Modal from './Modal';

const EXPORT_FILE_NAME = 'fee-configurations';

type TableParams = {
  level?: FeeCommissionLevel;
  transactionType?: TransactionType;
  direction?: TransactionDirection;
  country?: CountryCode;
  currency?: CurrencyCode;
};

const initialTableParams: TableParams = {
  //
};

const transactionTypeDirections = transactionService.getTransactionTypeDirections();

const FeeCommissionsPage = () => {
  const filtersForm = useForm<TableParams>();
  const lang = useLang();
  const table = useTable<FeeCommission, TableParams>([config.FEE_COMMISSIONS_QUERY_KEY], initialTableParams);

  const currentDirectionFilter = useFormWatch('direction', filtersForm);
  const currentTransactionTypeFilter = useFormWatch('transactionType', filtersForm);

  const [feeCommissionId, setFeeCommissionId] = useState<Uuid>();

  const modal = useQueryModal(config.FEE_COMMISSIONS_QUERY_KEY, setFeeCommissionId);

  const feeCommissionsParams: FeeCommissionsParams = {
    page: table.page,
    level: table.params.level || undefined,
    transactionType: table.params.transactionType || undefined,
    direction: table.params.direction || undefined,
    country: table.params.country || undefined,
    currency: table.params.currency || undefined,
  };

  const countriesQuery = useAllCountriesQuery();
  const feeCommissionsQuery = useFeeCommissionsQuery(feeCommissionsParams);
  const feeCommissionQuery = useFeeCommissionQuery(feeCommissionId);

  const handleCreateClick = () => {
    modal.show();
  };

  const handleViewClick = (feeCommission: FeeCommission) => () => {
    modal.show(feeCommission.id);
  };

  useTableQuery(table, feeCommissionsQuery);

  const formatFeeCommissionName = (feeCommission: FeeCommission) => feeCommission.name;

  const formatFeeCommissionLevel = useCallback((feeCommission: FeeCommission) => lang.get(`feeCommission.levels.${feeCommission.level.toLowerCase()}`), [lang]);

  const formatFeeCommissionCountry = useCallback((feeCommission: FeeCommission) => {
    return feeCommission.country
      ? formatter.formatCountry(countriesQuery.data ?? [], feeCommission.country)
      : lang.get('common.actions.any');
  }, [lang, countriesQuery.data]);

  const formatFeeCommissionCurrency = useCallback((feeCommission: FeeCommission) => feeCommission.currency ?? lang.get('common.actions.any'), [lang]);

  const formatFeeCommissionTransactionType = useCallback((feeCommission: FeeCommission) => {
    let label = lang.get(`transaction.types.${feeCommission.transactionType.toLowerCase()}`);

    if (feeCommission.feeFrequency) {
      label += ` (${lang.get(`feeCommission.frequencies.${feeCommission.feeFrequency.toLowerCase()}`)})`;
    }

    return label;
  }, [lang]);

  const formatFeeCommissionDirection = useCallback((feeCommission: FeeCommission) => {
    return feeCommission.direction
      ? lang.get(`transaction.directions.${feeCommission.direction.toLowerCase()}`)
      : '-';
  }, [lang]);

  const formatFeeCommissionFrequency = useCallback((feeCommission: FeeCommission) => {
    return feeCommission.feeFrequency
      ? lang.get(`feeCommission.frequencies.${feeCommission.feeFrequency.toLowerCase()}`)
      : '-';
  }, [lang]);

  const formatFeeCommissionTransactionFee = (feeCommission: FeeCommission) => {
    const fixedFee = feeCommission.fixedTransactionFee && formatter.formatCurrency(feeCommission.fixedTransactionFee, feeCommission.transactionFeeCurrency);
    const percentFee = feeCommission.percentTransactionFee && formatter.formatPercent(feeCommission.percentTransactionFee);

    return [fixedFee, percentFee].filter(Boolean).join(' + ') || '-';
  };

  const formatFeeCommissionFxMarkup = (feeCommission: FeeCommission) => formatter.formatPercent(feeCommission.fxMarkup);

  const formatFeeCommissionMinTransactionFeeAmount = (feeCommission: FeeCommission) => formatter.formatCurrency(feeCommission.minTransactionFeeAmount, feeCommission.transactionFeeCurrency);

  const formatFeeCommissionMaxTransactionFeeAmount = (feeCommission: FeeCommission) => formatter.formatCurrency(feeCommission.maxTransactionFeeAmount, feeCommission.transactionFeeCurrency);

  const exportColumns: ExportColumns<FeeCommission> = useMemo(() => [
    {
      title: lang.get('feeCommission.list.name'),
      render: (feeCommission) => formatFeeCommissionName(feeCommission),
    }, {
      title: lang.get('feeCommission.list.level'),
      render: (feeCommission) => formatFeeCommissionLevel(feeCommission),
    }, {
      title: lang.get('feeCommission.list.transactionType'),
      render: (feeCommission) => formatFeeCommissionTransactionType(feeCommission),
    }, {
      title: lang.get('feeCommission.list.direction'),
      render: (feeCommission) => formatFeeCommissionDirection(feeCommission),
    }, {
      title: lang.get('feeCommission.list.frequency'),
      render: (feeCommission) => formatFeeCommissionFrequency(feeCommission),
    }, {
      title: lang.get('feeCommission.list.country'),
      render: (feeCommission) => formatFeeCommissionCountry(feeCommission),
    }, {
      title: lang.get('feeCommission.list.currency'),
      render: (feeCommission) => formatFeeCommissionCurrency(feeCommission),
    }, {
      title: lang.get('feeCommission.list.transactionFee'),
      render: (feeCommission) => formatFeeCommissionTransactionFee(feeCommission),
    }, {
      title: lang.get('feeCommission.list.minTransactionFeeAmount'),
      render: (feeCommission) => formatFeeCommissionMinTransactionFeeAmount(feeCommission),
    }, {
      title: lang.get('feeCommission.list.maxTransactionFeeAmount'),
      render: (feeCommission) => formatFeeCommissionMaxTransactionFeeAmount(feeCommission),
    }, {
      title: lang.get('feeCommission.list.fxMarkup'),
      render: (feeCommission) => formatFeeCommissionFxMarkup(feeCommission),
    }, {
      title: lang.get('feeCommission.list.startDate'),
      render: (feeCommission) => feeCommission.startDate ? formatter.formatDate(feeCommission.startDate) : '-',
    }, {
      title: lang.get('feeCommission.list.endDate'),
      render: (feeCommission) => feeCommission.endDate ? formatter.formatDate(feeCommission.endDate) : '-',
    }, {
      title: lang.get('feeCommission.list.status'),
      render: (feeCommission) => lang.get(feeCommission.active ? 'common.activityStatuses.active' : 'common.activityStatuses.inactive'),
    },
  ], [
    lang,
    formatFeeCommissionLevel,
    formatFeeCommissionTransactionType,
    formatFeeCommissionDirection,
    formatFeeCommissionFrequency,
    formatFeeCommissionCountry,
    formatFeeCommissionCurrency,
  ]);

  const tableColumns: TableColumns<FeeCommission> = [
    {
      className: styles.table__name,
      key: 'name',
      title: lang.get('feeCommission.list.name'),
      render: (_, feeCommission) => <Table.Truncate>{formatFeeCommissionName(feeCommission)}</Table.Truncate>,
    }, {
      key: 'level',
      title: lang.get('feeCommission.list.level'),
      render: (_, feeCommission) => formatFeeCommissionLevel(feeCommission),
    }, {
      key: 'transactionType',
      title: lang.get('feeCommission.list.transactionType'),
      render: (_, feeCommission) => formatFeeCommissionTransactionType(feeCommission),
    }, {
      key: 'direction',
      title: lang.get('feeCommission.list.direction'),
      render: (_, feeCommission) => formatFeeCommissionDirection(feeCommission),
    }, {
      key: 'country',
      title: lang.get('feeCommission.list.country'),
      render: (_, feeCommission) => formatFeeCommissionCountry(feeCommission),
    }, {
      key: 'currency',
      title: lang.get('feeCommission.list.currency'),
      render: (_, feeCommission) => formatFeeCommissionCurrency(feeCommission),
    }, {
      className: styles.table__amount,
      key: 'transactionFee',
      title: lang.get('feeCommission.list.transactionFee'),
      render: (_, feeCommission) => formatFeeCommissionTransactionFee(feeCommission),
    }, {
      className: styles.table__amount,
      key: 'minTransactionFeeAmount',
      title: lang.get('feeCommission.list.minTransactionFeeAmount'),
      render: (_, feeCommission) => formatFeeCommissionMinTransactionFeeAmount(feeCommission),
    }, {
      className: styles.table__amount,
      key: 'maxTransactionFeeAmount',
      title: lang.get('feeCommission.list.maxTransactionFeeAmount'),
      render: (_, feeCommission) => formatFeeCommissionMaxTransactionFeeAmount(feeCommission),
    }, {
      className: styles.table__amount,
      key: 'fxMarkup',
      title: lang.get('feeCommission.list.fxMarkup'),
      render: (_, feeCommission) => formatFeeCommissionFxMarkup(feeCommission),
    }, {
      key: 'status',
      title: lang.get('feeCommission.list.status'),
      render: (_, feeCommission) => <ActivityStatus active={feeCommission.active} />,
    },
  ];

  return (
    <Can permissions={[UserPermission.FEE_COMMISSIONS_VIEW, UserPermission.FEE_COMMISSIONS_UPDATE]}>
      <TableView
        title={lang.get('feeCommission.list.title')}
        actions={(
          <Flex gap="small" wrap="wrap">
            <TableView.ExportButton<FeeCommission>
              table={table}
              type="default"
              fileName={EXPORT_FILE_NAME}
              columns={exportColumns}
              fetchData={() => fetchPaginatedResponseFully(feeCommissionService.getFeeCommissions, feeCommissionsParams)}
            />
            <Can permissions={[UserPermission.FEE_COMMISSIONS_UPDATE]}>
              <Button icon={<Plus />} onClick={handleCreateClick}>
                {lang.get('feeCommission.list.create')}
              </Button>
            </Can>
          </Flex>
        )}
      >

        <TableView.Filters<TableParams>
          form={filtersForm}
          initialValues={initialTableParams}
          values={table.params}
          onSubmit={table.setParams}
        >
          <Form.Item name="level" label={lang.get('feeCommission.filters.level')}>
            <Select
              placeholder={lang.get('common.actions.all')}
              options={Object.values(FeeCommissionLevel).map((level) => ({
                value: level,
                label: lang.get(`feeCommission.levels.${level.toLowerCase()}`),
              }))}
              allowClear
            />
          </Form.Item>
          <Form.Item name="transactionType" label={lang.get('feeCommission.filters.transactionType')}>
            <Select
              placeholder={lang.get('common.actions.all')}
              options={Object.values(TransactionType).map((type) => ({
                value: type,
                label: lang.get(`transaction.types.${type.toLowerCase()}`),
                disabled: Boolean(currentDirectionFilter && !transactionTypeDirections[type].includes(currentDirectionFilter)),
              }))}
              allowClear
            />
          </Form.Item>
          <Form.Item name="direction" label={lang.get('feeCommission.filters.direction')}>
            <Select
              placeholder={lang.get('common.actions.all')}
              options={Object.values(TransactionDirection).map((direction) => ({
                value: direction,
                label: lang.get(`transaction.directions.${direction.toLowerCase()}`),
                disabled: Boolean(currentTransactionTypeFilter && !transactionTypeDirections[currentTransactionTypeFilter].includes(direction)),
              }))}
              allowClear
            />
          </Form.Item>
          <Form.Item name="country" label={lang.get('common.filters.country')}>
            <CountrySelect
              placeholder={lang.get('common.actions.all')}
              allowClear
              disabled={currentTransactionTypeFilter && !transactionService.isTransactionTypeSupportsCountry(currentTransactionTypeFilter)}
            />
          </Form.Item>
          <Form.Item name="currency" label={lang.get('common.filters.currency')}>
            <CurrencySelect placeholder={lang.get('common.actions.all')} allowClear />
          </Form.Item>
        </TableView.Filters>

        <TableView.Table<FeeCommission>
          table={table}
          columns={tableColumns}
          rowKey={(feeCommission) => feeCommission.id}
          loading={feeCommissionsQuery.isFetching}
          clickable
          onRow={(feeCommission) => ({ onClick: handleViewClick(feeCommission) })}
        />

        <Modal
          feeCommission={feeCommissionQuery.data || undefined}
          open={!feeCommissionQuery.isFetching && modal.open}
          onClose={modal.hide}
        />

      </TableView>
    </Can>
  );
};

export default FeeCommissionsPage;
