import React, { FC } from 'react';

import { ForbiddenPage } from 'pages';
import { Can } from 'components/layout';
import { UserPermission } from 'types/models';
import { RouteProps } from 'types/routes';

import PrivateRoute from '../PrivateRoute';

type ProtectedRouteProps = RouteProps & {
  permissions: UserPermission[];
};

const ProtectedRoute: FC<ProtectedRouteProps> = ({ children, permissions }) => {
  return (
    <PrivateRoute>
      <Can permissions={permissions} fallback={<ForbiddenPage />}>
        {children}
      </Can>
    </PrivateRoute>
  );
};

export default ProtectedRoute;
