import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { config, yup } from 'data';
import { url } from 'helpers';
import { authService } from 'services';
import { useForm, useLang, useMutation } from 'hooks';
import { PageLayout } from 'layouts';
import { Button, Flex, Form, Input } from 'components/ui';

type FormValues = {
  email: string;
  password: string;
  passwordConfirmation: string;
  code: string;
};

const initialValues: FormValues = {
  email: '',
  password: '',
  passwordConfirmation: '',
  code: '',
};

const emailValidationSchema = yup.object({
  email: yup.string().required().email(),
  code: yup.string().defined(),
  password: yup.string().defined(),
  passwordConfirmation: yup.string().defined(),
});

const confirmationValidationSchema = emailValidationSchema.shape({
  code: yup.string().required().trim().length(config.VERIFICATION_CODE_LENGTH),
  password: yup.string().required().password(),
  passwordConfirmation: yup.string().required().confirmation(yup.ref('password')),
});

const PasswordResetPage = () => {
  const form = useForm<FormValues>();
  const lang = useLang();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');

  const resetPasswordMutation = useMutation({
    mutationFn: authService.resetPassword,
  });

  const resetPasswordConfirmMutation = useMutation({
    mutationFn: authService.resetPasswordConfirm,
    onSuccess: () => navigate(url.toDashboard()),
    successNotification: lang.get('passwordReset.success'),
  });

  const handleSubmit = async (values: FormValues) => {
    if (!values.code) {
      await resetPasswordMutation.mutateAsync(values);

      setEmail(values.email);

      return false;
    }

    await resetPasswordConfirmMutation.mutateAsync(values);
  };

  const confirmation = Boolean(email);
  const loading = resetPasswordMutation.isPending || resetPasswordConfirmMutation.isPending;

  return (
    <PageLayout
      title={lang.get('passwordReset.title')}
      heading={lang.get('passwordReset.heading')}
      caption={
        confirmation
          ? lang.get('passwordReset.confirmationCaption', { email })
          : lang.get('passwordReset.emailCaption')
      }
    >
      <Form
        form={form}
        initialValues={initialValues}
        validationSchema={confirmation ? confirmationValidationSchema : emailValidationSchema}
        onFinish={handleSubmit}
      >
        <Form.Item name="email" label={lang.get('common.form.email.label')} hidden={confirmation}>
          <Input type="email" placeholder={lang.get('common.form.email.placeholder')} />
        </Form.Item>
        <Form.Item name="code" label={lang.get('passwordReset.code.label')} hidden={!confirmation}>
          <Input placeholder={lang.get('passwordReset.code.placeholder')} />
        </Form.Item>
        <Form.Item name="password" label={lang.get('passwordReset.password.label')} hidden={!confirmation}>
          <Input type="password" placeholder={lang.get('passwordReset.password.placeholder')} />
        </Form.Item>
        <Form.Item name="passwordConfirmation" label={lang.get('passwordReset.passwordConfirmation.label')} hidden={!confirmation}>
          <Input type="password" placeholder={lang.get('passwordReset.passwordConfirmation.placeholder')} />
        </Form.Item>
        <Form.Item>
          <Flex gap="small" vertical>
            <Button htmlType="submit" block loading={loading}>
              {lang.get('common.actions.continue')}
            </Button>
            <Link to={url.toLogin()}>
              <Button type="link" block>
                {lang.get('passwordReset.back')}
              </Button>
            </Link>
          </Flex>
        </Form.Item>
      </Form>
    </PageLayout>
  );
};

export default PasswordResetPage;
