import React, { FC } from 'react';

import { useAuth } from 'hooks';
import { businessAccountService } from 'services';
import { Select } from 'components/ui';
import { SelectProps } from 'types/components';
import { BusinessAccountType, UserPermission } from 'types/models';

type BusinessAccountSelectProps = Omit<SelectProps, 'onSearch'>;

const BusinessAccountSelect: FC<BusinessAccountSelectProps> = (props) => {
  const auth = useAuth();

  const handleSearch = async (query: string) => {
    const canViewCompany = auth.can(UserPermission.BA_VIEW, UserPermission.BA_UPDATE);
    const canViewIndividual = auth.can(UserPermission.INDIVIDUALS_VIEW, UserPermission.INDIVIDUALS_UPDATE);

    const { list } = await businessAccountService.getBusinessAccounts({
      search: query || undefined,
      type: (canViewCompany && canViewIndividual) ? undefined : (
        canViewIndividual ? BusinessAccountType.INDIVIDUAL : BusinessAccountType.COMPANY
      ),
    });

    return list.map((businessAccount) => ({
      value: businessAccount.id,
      label: businessAccount.name,
    }));
  };

  return (
    <Select.Ajax onSearch={handleSearch} {...props} />
  );
};

export default BusinessAccountSelect;
