import React from 'react';
import { Table as BaseTable } from 'antd';
import { AnyObject } from 'antd/es/_util/type';
import cx from 'classnames';

import { useLang } from 'hooks';
import { ArrowLeft, ArrowRight } from 'components/icons';
import { TableProps } from 'types/components';

import Button from '../Button';

import styles from './styles.module.css';

import ActionButton from './ActionButton';
import Actions from './Actions';
import Truncate from './Truncate';

const Table = <DataType extends AnyObject>({
  className,
  clickable,
  pagination = false,
  scroll = { x: 0 },
  ...props
}: TableProps<DataType>) => {
  const lang = useLang();

  return (
    <BaseTable
      className={cx(
        styles.root,
        clickable && styles.root_clickable,
        className,
      )}
      pagination={pagination && {
        ...pagination,
        showSizeChanger: false,
        itemRender: (_, type, originalElement) => {
          switch (type) {
            case 'prev':
              return (
                <Button type="default">
                  <ArrowLeft />
                  {lang.get('common.pagination.previous')}
                </Button>
              );
            case 'next':
              return (
                <Button type="default">
                  {lang.get('common.pagination.next')}
                  <ArrowRight />
                </Button>
              );
          }

          return originalElement;
        },
      }}
      scroll={scroll}
      {...props}
    />
  );
};

export default Object.assign(Table, { ActionButton, Actions, Truncate });
