import React, { FC } from 'react';

import { IconProps } from 'types/components';

import Icon from './Icon';

const Info: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path d="M10 2a8 8 0 1 1-8 8 8 8 0 0 1 8-8Zm0 11.2V10m0-3.2h0" />
  </Icon>
);

export default Info;
