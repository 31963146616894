import React, { FC, useEffect } from 'react';

import { config, moment, yup } from 'data';
import { costService } from 'services';
import { useAuth, useForm, useFormWatch, useLang, useMutation, useQueryInvalidate } from 'hooks';
import { useProviderQuery } from 'hooks/queries';
import { Trash } from 'components/icons';
import { CountrySelect, CurrencySelect, ProviderSelect } from 'components/layout';
import { Checkbox, DateRangePicker, Flex, Form, Input, Modal, PopconfirmButton, Select, TextArea } from 'components/ui';
import { DateRangePickerValue, ModalBaseProps } from 'types/components';
import { Cost, TransactionFeeType, UserPermission } from 'types/models';
import { CostParams } from 'types/services';

type FormValues = CostParams & {
  period?: DateRangePickerValue;
};

const feeTypes = Object.values(TransactionFeeType);

const initialValues: Omit<
  FormValues,
  | 'transactionFee'
  | 'fxMarkup'
  | 'minTransactionFeeAmount'
  | 'maxTransactionFeeAmount'
> = {
  providerId: '',
  country: null,
  currency: null,
  transactionFeeType: TransactionFeeType.PERCENT,
};

const validationSchema = yup.object().shape({
  providerId: yup.string().required().uuid(),
  country: yup.string().notRequired().country().default(initialValues.country),
  currency: yup.string().notRequired().currency().default(initialValues.currency),
  transactionFeeType: yup.string().required().oneOf(feeTypes),
  transactionFee: yup
    .number()
    .required()
    .decimal()
    .transform((value) => value || 0)
    .when('transactionFeeType', ([type], schema) => {
      if (type === TransactionFeeType.PERCENT) {
        return schema.percent();
      }

      return schema.min(config.FEE_COMMISSION_MIN).max(config.FEE_COMMISSION_MAX);
    }),
  fxMarkup: yup
    .number()
    .required()
    .percent()
    .decimal()
    .transform((value) => value || 0),
  minTransactionFeeAmount: yup
    .number()
    .required()
    .decimal()
    .min(config.FEE_COMMISSION_MIN)
    .max(config.FEE_COMMISSION_MAX)
    .transform((value) => value || 0)
    .when(['transactionFeeType', 'maxTransactionFeeAmount'], ([type, max], schema) => {
      if (type !== TransactionFeeType.PERCENT) {
        return schema.notRequired();
      }

      return max ? schema.max(max) : schema;
    }),
  maxTransactionFeeAmount: yup
    .number()
    .required()
    .decimal()
    .min(config.FEE_COMMISSION_MIN)
    .max(config.FEE_COMMISSION_MAX)
    .transform((value) => value || 0)
    .when(['transactionFeeType', 'minTransactionFeeAmount'], ([type, min], schema) => {
      if (type !== TransactionFeeType.PERCENT) {
        return schema.notRequired();
      }

      return min ? schema.min(min) : schema;
    }),
  c2c: yup.boolean().notRequired(),
  c2b: yup.boolean().notRequired(),
  b2c: yup.boolean().notRequired(),
  b2b: yup.boolean().notRequired(),
  notes: yup.string().notRequired().trim().max(config.TEXT_MAX_LENGTH),
}, [['minTransactionFeeAmount', 'maxTransactionFeeAmount']]);

type CostModalProps = ModalBaseProps & {
  cost?: Cost;
};

const CostModal: FC<CostModalProps> = ({
  cost,
  open,
  onClose,
}) => {
  const auth = useAuth();
  const form = useForm<FormValues>();
  const lang = useLang();
  const queryInvalidate = useQueryInvalidate();

  const currentProviderId = useFormWatch('providerId', form);
  const currentFeeType = useFormWatch('transactionFeeType', form);

  const isPercentFeeType = currentFeeType === TransactionFeeType.PERCENT;
  const costId = cost?.id ?? '';

  const providerQuery = useProviderQuery(currentProviderId);

  const invalidateCostQueries = async () => {
    await queryInvalidate([config.COSTS_QUERY_KEY]);
  };

  const createCostMutation = useMutation({
    mutationFn: costService.createCost,
    onSuccess: invalidateCostQueries,
    successNotification: lang.get('cost.modal.createSuccess'),
  });

  const updateCostMutation = useMutation({
    mutationFn: (values: FormValues) => costService.updateCost(costId, values),
    onSuccess: invalidateCostQueries,
    successNotification: lang.get('cost.modal.updateSuccess'),
  });

  const activateCostMutation = useMutation({
    mutationFn: () => costService.activateCost(costId),
    onSuccess: invalidateCostQueries,
    successNotification: lang.get('cost.modal.activateSuccess'),
  });

  const deactivateCostMutation = useMutation({
    mutationFn: () => costService.deactivateCost(costId),
    onSuccess: invalidateCostQueries,
    successNotification: lang.get('cost.modal.deactivateSuccess'),
  });

  const deleteCostMutation = useMutation({
    mutationFn: () => costService.deleteCost(costId),
    onSuccess: invalidateCostQueries,
    successNotification: lang.get('cost.modal.deleteSuccess'),
  });

  const handleSubmit = async (values: FormValues) => {
    const [startDate, endDate] = values.period ?? [];

    values.startDate = startDate ? startDate.startOf('day').toISOString() : null;
    values.endDate = endDate ? endDate.endOf('day').toISOString() : null;

    delete values.period;

    cost
      ? await updateCostMutation.mutateAsync(values)
      : await createCostMutation.mutateAsync(values);

    onClose();
  };

  const handleActivate = async () => {
    await activateCostMutation.mutateAsync();

    onClose();
  };

  const handleDeactivate = async () => {
    await deactivateCostMutation.mutateAsync();

    onClose();
  };

  const handleDelete = async () => {
    await deleteCostMutation.mutateAsync();

    onClose();
  };

  useEffect(() => {
    if (open && cost) {
      form.setFieldsValue({
        ...cost,
        providerId: cost.provider.id,
        period: [
          cost.startDate && moment(cost.startDate),
          cost.endDate && moment(cost.endDate),
        ],
      });
    }
  }, [cost, open, form]);

  useEffect(() => {
    if (form.isFieldTouched('transactionFeeType')) {
      form.resetFields(['transactionFee', 'minTransactionFeeAmount', 'maxTransactionFeeAmount']);
    }
  }, [form, isPercentFeeType]);

  const canEdit = auth.can(UserPermission.COSTS_UPDATE);
  const isEditing = Boolean(cost);
  const hasActions = canEdit && isEditing;
  const currency = providerQuery.data?.currency ?? config.DEFAULT_CURRENCY;

  return (
    <Modal
      title={isEditing ? lang.get('cost.modal.updateTitle') : lang.get('cost.modal.createTitle')}
      caption={isEditing ? lang.get('cost.modal.updateCaption') : lang.get('cost.modal.createCaption')}
      okText={isEditing ? lang.get('common.actions.save') : lang.get('common.actions.create')}
      cancelText={!canEdit ? lang.get('common.actions.close') : null}
      okButtonProps={{ hidden: !canEdit }}
      extraActions={hasActions && (
        <PopconfirmButton
          title={lang.get('cost.modal.deleteTitle')}
          icon={<Trash />}
          danger
          loading={deleteCostMutation.isPending}
          onConfirm={handleDelete}
        >
          {lang.get('common.actions.delete')}
        </PopconfirmButton>
      )}
      width="small"
      open={open}
      confirmLoading={createCostMutation.isPending || updateCostMutation.isPending}
      onOk={form.submit}
      onCancel={onClose}
    >
      <Form
        form={form}
        initialValues={initialValues}
        validationSchema={validationSchema}
        disabled={!canEdit}
        onFinish={handleSubmit}
      >

        <Form.ActionsItem hidden={!hasActions}>
          {cost?.active ? (
            <PopconfirmButton
              title={lang.get('cost.modal.deactivateTitle')}
              danger
              loading={deactivateCostMutation.isPending}
              onConfirm={handleDeactivate}
            >
              {lang.get('common.actions.deactivate')}
            </PopconfirmButton>
          ) : (
            <PopconfirmButton
              title={lang.get('cost.modal.activateTitle')}
              type="primary"
              ghost
              loading={activateCostMutation.isPending}
              onConfirm={handleActivate}
            >
              {lang.get('common.actions.activate')}
            </PopconfirmButton>
          )}
        </Form.ActionsItem>

        <Form.Divider hidden={!hasActions} />

        <Form.Item name="providerId" label={lang.get('cost.modal.provider.label')}>
          <ProviderSelect placeholder={lang.get('cost.modal.provider.placeholder')} />
        </Form.Item>
        <Form.Columns>
          <Form.Item name="country" label={lang.get('common.form.country.label')}>
            <CountrySelect placeholder={lang.get('common.actions.any')} allowClear />
          </Form.Item>
          <Form.Item name="currency" label={lang.get('common.form.currency.label')}>
            <CurrencySelect placeholder={lang.get('common.actions.any')} allowClear />
          </Form.Item>
        </Form.Columns>
        <Form.Item name="transactionFeeType" label={lang.get('cost.modal.transactionFeeType.label')}>
          <Select
            placeholder={lang.get('cost.modal.transactionFeeType.placeholder')}
            options={feeTypes.map((type) => ({
              value: type,
              label: lang.get(`cost.feeTypes.${type.toLowerCase()}`),
            }))}
          />
        </Form.Item>
        <Form.Columns>
          <Form.Item name="transactionFee" label={lang.get('cost.modal.transactionFee.label')}>
            <Input.Number placeholder={lang.get('cost.modal.transactionFee.placeholder')} suffix={isPercentFeeType ? '%' : (currency ?? ' ')} />
          </Form.Item>
          <Form.Item name="fxMarkup" label={lang.get('cost.modal.fxMarkup.label')}>
            <Input.Number placeholder={lang.get('cost.modal.fxMarkup.placeholder')} suffix="%" />
          </Form.Item>
        </Form.Columns>
        <Form.Columns>
          <Form.Item
            name="minTransactionFeeAmount"
            label={lang.get('cost.modal.minTransactionFeeAmount.label')}
            hidden={!isPercentFeeType}
          >
            <Input.Number placeholder={lang.get('cost.modal.minTransactionFeeAmount.placeholder')} suffix={currency ?? ' '} />
          </Form.Item>
          <Form.Item
            name="maxTransactionFeeAmount"
            label={lang.get('cost.modal.maxTransactionFeeAmount.label')}
            hidden={!isPercentFeeType}
          >
            <Input.Number placeholder={lang.get('cost.modal.maxTransactionFeeAmount.placeholder')} suffix={currency ?? ' '} />
          </Form.Item>
        </Form.Columns>
        <Form.Item name="period" label={lang.get('cost.modal.period.label')}>
          <DateRangePicker minDate={moment().startOf('day')} />
        </Form.Item>
        <Form.Item label={lang.get('cost.modal.remittanceType.label')}>
          <Flex gap="large" wrap="wrap">
            <Form.CheckboxItem name="c2c" noStyle>
              <Checkbox>{lang.get('cost.modal.c2c.label')}</Checkbox>
            </Form.CheckboxItem>
            <Form.CheckboxItem name="c2b" noStyle>
              <Checkbox>{lang.get('cost.modal.c2b.label')}</Checkbox>
            </Form.CheckboxItem>
            <Form.CheckboxItem name="b2c" noStyle>
              <Checkbox>{lang.get('cost.modal.b2c.label')}</Checkbox>
            </Form.CheckboxItem>
            <Form.CheckboxItem name="b2b" noStyle>
              <Checkbox>{lang.get('cost.modal.b2b.label')}</Checkbox>
            </Form.CheckboxItem>
          </Flex>
        </Form.Item>
        <Form.Item name="notes" label={lang.get('common.form.notes.label')}>
          <TextArea placeholder={lang.get('common.form.notes.placeholder')} />
        </Form.Item>

      </Form>
    </Modal>
  );
};

export default CostModal;
