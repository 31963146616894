import React, { FC, Fragment } from 'react';

import { Divider } from 'components/ui';
import { BusinessAccountBalance } from 'types/models';

import styles from './styles.module.css';

import Info from '../Info';

type BalanceDetailsProps = {
  details: BusinessAccountBalance['details'];
};

const BalanceDetails: FC<BalanceDetailsProps> = ({ details }) => {
  return (
    <div className={styles.root}>
      {details.map((detail) => (
        <Fragment key={detail.virtualAccountId}>
          <div className={styles.item}>
            <div className={styles.item__title}>{detail.title} / {detail.externalId}</div>
            <div>
              {detail.balances.map((balance) => (
                <Info
                  key={balance.currency}
                  currentAmount={balance.balance}
                  incomingPendingAmount={balance.incomingPendingBalance}
                  outgoingPendingAmount={balance.outgoingPendingBalance}
                  currency={balance.currency}
                />
              ))}
            </div>
          </div>
          <Divider className={styles.divider} />
        </Fragment>
      ))}
    </div>
  );
};

export default BalanceDetails;
