import React, { FC, Fragment } from 'react';
import { Link } from 'react-router-dom';

import { url } from 'helpers';
import { useLang } from 'hooks';
import { Trans } from 'components/ui';
import { BusinessAccount, Transaction, TransactionType } from 'types/models';

type TitleProps = {
  businessAccount?: BusinessAccount;
  transaction?: Transaction;
};

const Title: FC<TitleProps> = ({ businessAccount, transaction }) => {
  const lang = useLang();

  const label = (transaction && transaction.transactionType !== TransactionType.ADJUSTMENT)
    ? lang.get('transaction.modal.transferTitle')
    : lang.get('transaction.modal.adjustmentTitle');

  return (
    <Trans
      transKey={businessAccount ? 'transaction.modal.businessAccountTitle' : 'transaction.modal.title'}
      components={{
        Link: businessAccount
          ? <Link to={url.toBusinessAccount(businessAccount.id)}>{businessAccount.name}</Link>
          : <Fragment />,
      }}
      replace={{ label }}
    />
  );
};

export default Title;
