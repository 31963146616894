import React, { FC } from 'react';

import { useLang } from 'hooks';
import { Modal, Paragraph } from 'components/ui';
import { ModalBaseProps } from 'types/components';
import { TransactionBundleExtended } from 'types/models';

import styles from './styles.module.css';

type BundleDetailsModalProps = ModalBaseProps & {
  bundle: TransactionBundleExtended;
};

const BundleDetailsModal: FC<BundleDetailsModalProps> = ({
  bundle,
  open,
  onClose,
}) => {
  const lang = useLang();

  let data = bundle.errorText;

  try {
    data = JSON.parse(bundle.errorText);
  } catch (error) {
    //
  }

  return (
    <Modal
      title={lang.get('transactionBundle.detailsModal.title', { name: bundle.client.name })}
      cancelText={lang.get('common.actions.close')}
      okButtonProps={{ hidden: true }}
      width="small"
      open={open}
      onCancel={onClose}
    >
      <Paragraph className={styles.details__data}>
        <pre>{JSON.stringify(data, null, 2)}</pre>
      </Paragraph>
    </Modal>
  );
};

export default BundleDetailsModal;
