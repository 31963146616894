import { config } from 'data';
import { feeGroupService } from 'services';
import { useQuery } from 'hooks';
import { Uuid } from 'types/common';

const useFeeGroupQuery = (id?: Uuid) => useQuery({
  queryKey: [config.FEE_GROUP_QUERY_KEY, id],
  queryFn: () => id ? feeGroupService.getFeeGroup(id) : null,
});

export default useFeeGroupQuery;
