import React, { useMemo } from 'react';

import { config, moment } from 'data';
import { formatter } from 'helpers';
import { reportService } from 'services';
import { fetchPaginatedResponseFully } from 'services/helpers';
import { useLang, useQuery, useTable, useTableQuery } from 'hooks';
import { DashboardLayout } from 'layouts';
import { BusinessAccountIcon, TableView } from 'components/layout';
import { DateRangePicker, Form, Table } from 'components/ui';
import { ExportColumns, TableColumns } from 'types/components';
import { ReportBusinessAccountsDailyBalanceItem } from 'types/models';
import { ReportBusinessAccountsDailyBalanceParams } from 'types/services';

import styles from './styles.module.css';

const EXPORT_FILE_NAME = 'report-business-accounts-daily-balance';

type TableParams = {
  search?: string;
  dates: [string, string];
};

const initialTableParams: TableParams = {
  dates: [
    moment().subtract(1, 'day').startOf('day').toISOString(),
    moment().subtract(1, 'day').endOf('day').toISOString(),
  ],
};

const BusinessAccountsDailyBalancePage = () => {
  const lang = useLang();
  const table = useTable<ReportBusinessAccountsDailyBalanceItem, TableParams>([config.BUSINESS_ACCOUNTS_DAILY_BALANCE_QUERY_KEY], initialTableParams);

  const reportParams: ReportBusinessAccountsDailyBalanceParams = {
    page: table.page,
    search: table.params.search || undefined,
    dateFrom: table.params.dates[0],
    dateTo: table.params.dates[1],
  };

  const reportQuery = useQuery({
    queryKey: [config.BUSINESS_ACCOUNTS_DAILY_BALANCE_QUERY_KEY, reportParams],
    queryFn: () => reportService.getBusinessAccountsDailyBalance(reportParams),
  });

  useTableQuery(table, reportQuery);

  const formatItemDate = (item: ReportBusinessAccountsDailyBalanceItem) => formatter.formatDate(item.date);

  const formatItemBusinessAccount = (item: ReportBusinessAccountsDailyBalanceItem) => item.client.name;

  const formatItemCurrency = (item: ReportBusinessAccountsDailyBalanceItem) => item.currency;

  const formatItemAvailable = (item: ReportBusinessAccountsDailyBalanceItem) => formatter.formatNumber(item.available);

  const formatItemOutgoingPending = (item: ReportBusinessAccountsDailyBalanceItem) => formatter.formatNumber(item.outgoingPending);

  const formatItemIncomingPending = (item: ReportBusinessAccountsDailyBalanceItem) => formatter.formatNumber(item.incomingPending);

  const formatItemTotal = (item: ReportBusinessAccountsDailyBalanceItem) => formatter.formatNumber(item.total);

  const exportColumns: ExportColumns<ReportBusinessAccountsDailyBalanceItem> = useMemo(() => [
    {
      title: lang.get('businessAccount.dailyBalance.date'),
      render: (item) => formatItemDate(item),
    }, {
      title: lang.get('businessAccount.dailyBalance.businessAccount'),
      render: (item) => formatItemBusinessAccount(item),
    }, {
      title: lang.get('businessAccount.dailyBalance.currency'),
      render: (item) => formatItemCurrency(item),
    }, {
      title: lang.get('businessAccount.dailyBalance.available'),
      render: (item) => formatItemAvailable(item),
    }, {
      title: lang.get('businessAccount.dailyBalance.outgoingPending'),
      render: (item) => formatItemOutgoingPending(item),
    }, {
      title: lang.get('businessAccount.dailyBalance.incomingPending'),
      render: (item) => formatItemIncomingPending(item),
    }, {
      title: lang.get('businessAccount.dailyBalance.total'),
      render: (item) => formatItemTotal(item),
    },
  ], [lang]);

  const tableColumns: TableColumns<ReportBusinessAccountsDailyBalanceItem> = [
    {
      className: styles.table__date,
      key: 'date',
      title: lang.get('businessAccount.dailyBalance.date'),
      render: (_, item) => formatItemDate(item),
    }, {
      key: 'businessAccount',
      title: lang.get('businessAccount.dailyBalance.businessAccount'),
      render: (_, item) => (
        <Table.Truncate title={formatItemBusinessAccount(item)}>
          <BusinessAccountIcon businessAccountType={item.client.type} />
          <span>{formatItemBusinessAccount(item)}</span>
        </Table.Truncate>
      ),
    }, {
      key: 'currency',
      title: lang.get('businessAccount.dailyBalance.currency'),
      render: (_, item) => formatItemCurrency(item),
    }, {
      className: styles.table__amount,
      key: 'available',
      title: lang.get('businessAccount.dailyBalance.available'),
      render: (_, item) => formatItemAvailable(item),
    }, {
      className: styles.table__balance,
      key: 'outgoingPending',
      title: lang.get('businessAccount.dailyBalance.outgoingPending'),
      render: (_, item) => formatItemOutgoingPending(item),
    }, {
      className: styles.table__balance,
      key: 'incomingPending',
      title: lang.get('businessAccount.dailyBalance.incomingPending'),
      render: (_, item) => formatItemIncomingPending(item),
    }, {
      className: styles.table__balance,
      key: 'total',
      title: lang.get('businessAccount.dailyBalance.total'),
      render: (_, item) => formatItemTotal(item),
    },
  ];

  return (
    <DashboardLayout title={lang.get('businessAccount.dailyBalance.listTitle')}>
      <TableView
        title={lang.get('businessAccount.dailyBalance.listTitle')}
        actions={(
          <TableView.ExportButton<ReportBusinessAccountsDailyBalanceItem>
            table={table}
            fileName={[
              EXPORT_FILE_NAME,
              moment(table.params.dates[0]).format(config.DATE_RAW_FORMAT),
              moment(table.params.dates[1]).format(config.DATE_RAW_FORMAT),
            ].join('-')}
            columns={exportColumns}
            fetchData={() => fetchPaginatedResponseFully(reportService.getBusinessAccountsDailyBalance, reportParams)}
          />
        )}
      >

        <TableView.Filters<TableParams>
          initialValues={initialTableParams}
          values={table.params}
          withSearch
          inline
          onSubmit={table.setParams}
        >
          <Form.Item name="dates">
            <DateRangePicker maxDate={moment().subtract(1, 'day').endOf('day')} allowClear={false} />
          </Form.Item>
        </TableView.Filters>

        <TableView.Table<ReportBusinessAccountsDailyBalanceItem>
          table={table}
          columns={tableColumns}
          rowKey={(item) => item.client.id + item.date + item.currency}
          loading={reportQuery.isFetching}
        />

      </TableView>
    </DashboardLayout>
  );
};

export default BusinessAccountsDailyBalancePage;
