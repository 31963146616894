import React, { FC } from 'react';

import { useLang } from 'hooks';
import { useAllCurrenciesQuery } from 'hooks/queries';
import { Select } from 'components/ui';
import { SelectProps } from 'types/components';
import { CurrencyCode } from 'types/models';

type CurrencySelectProps = Omit<SelectProps, 'options' | 'loading'> & {
  activeCodes?: CurrencyCode[];
  onlyActive?: boolean;
};

const CurrencySelect: FC<CurrencySelectProps> = ({
  activeCodes,
  onlyActive,
  showSearch = true,
  ...props
}) => {
  const lang = useLang();

  const currenciesQuery = useAllCurrenciesQuery({ active: onlyActive || undefined });

  const currencies = currenciesQuery.data?.filter((currency) => !activeCodes || activeCodes.includes(currency.code));

  return (
    <Select
      placeholder={lang.get('common.form.currency.placeholder')}
      options={currencies?.map((currency) => ({
        value: currency.code,
        label: currency.code,
      }))}
      loading={currenciesQuery.isFetching}
      showSearch={showSearch}
      {...props}
    />
  );
};

export default CurrencySelect;
