import { config } from 'data';
import { businessApplicationService } from 'services';
import { useQuery } from 'hooks';
import { BusinessApplicationsParams } from 'types/services';

const useBusinessApplicationsQuery = (params?: BusinessApplicationsParams) => useQuery({
  queryKey: [config.BUSINESS_APPLICATIONS_QUERY_KEY, params],
  queryFn: () => businessApplicationService.getBusinessApplications(params),
});

export default useBusinessApplicationsQuery;
