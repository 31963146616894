import React from 'react';

import { useAuth, useLang } from 'hooks';
import { DashboardLayout } from 'layouts';
import { Flex, Paragraph, Title } from 'components/ui';

import styles from './styles.module.css';

const DashboardPage = () => {
  const auth = useAuth();
  const lang = useLang();

  return (
    <DashboardLayout
      title={lang.get('dashboard.title')}
      showBreadcrumbs={false}
    >
      <Flex
        className={styles.root}
        align="center"
        justify="center"
        vertical
      >
        <Title>{lang.get('dashboard.heading', { name: auth.profile?.firstName ?? auth.profile?.email })}</Title>
        <Paragraph>{lang.get('dashboard.caption')}</Paragraph>
      </Flex>
    </DashboardLayout>
  );
};

export default DashboardPage;
