import { config } from 'data';
import { businessApplicationService } from 'services';
import { useQuery } from 'hooks';
import { Uuid } from 'types/common';

const useBusinessApplicationQuery = (id?: Uuid) => useQuery({
  queryKey: [config.BUSINESS_APPLICATION_QUERY_KEY, id],
  queryFn: () => id ? businessApplicationService.getBusinessApplication(id) : null,
});

export default useBusinessApplicationQuery;
