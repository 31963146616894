import React, { FC, useMemo } from 'react';
import truncate from 'lodash/truncate';

import { config, moment } from 'data';
import { formatter } from 'helpers';
import { transactionService } from 'services';
import { fetchPaginatedResponseFully } from 'services/helpers';
import { useLang, useTable, useTableQuery } from 'hooks';
import { useTransactionsQuery } from 'hooks/queries';
import { TableView } from 'components/layout';
import { Modal } from 'components/ui';
import { ExportColumns, ModalBaseProps, TableColumns } from 'types/components';
import { ReportProvidersDailySettlementItem, Transaction } from 'types/models';
import { TransactionsParams } from 'types/services';

import styles from './styles.module.css';

const EXPORT_FILE_NAME = 'report-providers-daily-settlement-transactions';

type SettlementTransactionsModalProps = ModalBaseProps & {
  item: ReportProvidersDailySettlementItem;
};

const SettlementTransactionsModal: FC<SettlementTransactionsModalProps> = ({ item, open, onClose }) => {
  const lang = useLang();
  const table = useTable<Transaction>([config.PROVIDERS_DAILY_SETTLEMENT_TRANSACTIONS_QUERY_KEY]);

  const transactionsParams: TransactionsParams = {
    page: table.page,
    clientId: item.client.id,
    originCurrency: item.currency,
    providerId: item.provider.id,
    status: transactionService.getTransactionAcceptedStatuses(),
    dateFrom: moment(item.date).startOf('day').toISOString(),
    dateTo: moment(item.date).endOf('day').toISOString(),
  };

  const transactionsQuery = useTransactionsQuery(transactionsParams);

  const handleClose = () => {
    table.reset();

    onClose();
  };

  useTableQuery(table, transactionsQuery);

  const formatTransactionId = (transaction: Transaction) => transaction.id;

  const formatTransactionClientReferenceId = (transaction: Transaction) => transaction.clientReferenceId ?? '-';

  const formatTransactionCurrency = (transaction: Transaction) => transaction.originCurrency;

  const formatTransactionAmount = (transaction: Transaction) => formatter.formatNumber(transaction.originAmount);

  const formatTransactionFees = (transaction: Transaction) => formatter.formatNumber(transaction.transactionFee + transaction.fxMarkupFee);

  const formatNetClientPayment = (transaction: Transaction) => formatter.formatNumber(transaction.originAmount + transaction.transactionFee);

  const formatProviderFees = (transaction: Transaction) => formatter.formatNumber(transaction.providerFee);

  const formatFeeIncome = (transaction: Transaction) => formatter.formatNumber(-(transaction.transactionFee + transaction.fxMarkupFee - transaction.providerFee));

  const formatTransactionDestinationCurrency = (transaction: Transaction) => transaction.destinationCurrency;

  const formatTransactionDestinationAmount = (transaction: Transaction) => formatter.formatNumber(transaction.destinationAmount);

  const exportColumns: ExportColumns<Transaction> = useMemo(() => [
    {
      title: lang.get('transaction.list.id'),
      render: (transaction) => formatTransactionId(transaction),
    }, {
      title: lang.get('transaction.list.clientReferenceId'),
      render: (transaction) => formatTransactionClientReferenceId(transaction),
    }, {
      title: lang.get('provider.dailySettlement.currency'),
      render: (transaction) => formatTransactionCurrency(transaction),
    }, {
      title: lang.get('provider.dailySettlement.transactionAmount'),
      render: (transaction) => formatTransactionAmount(transaction),
    }, {
      title: lang.get('provider.dailySettlement.transactionFees'),
      render: (transaction) => formatTransactionFees(transaction),
    }, {
      title: lang.get('provider.dailySettlement.netClientPayment'),
      render: (transaction) => formatNetClientPayment(transaction),
    }, {
      title: lang.get('provider.dailySettlement.providerFees'),
      render: (transaction) => formatProviderFees(transaction),
    }, {
      title: lang.get('provider.dailySettlement.feeIncome'),
      render: (transaction) => formatFeeIncome(transaction),
    }, {
      title: lang.get('provider.dailySettlement.destinationCurrency'),
      render: (transaction) => formatTransactionDestinationCurrency(transaction),
    }, {
      title: lang.get('provider.dailySettlement.destinationAmount'),
      render: (transaction) => formatTransactionDestinationAmount(transaction),
    },
  ], [lang]);

  const tableColumns: TableColumns<Transaction> = [
    {
      className: styles.table__id,
      key: 'id',
      title: lang.get('transaction.list.id'),
      render: (_, transaction) => (
        <span title={formatTransactionId(transaction)}>
          {truncate(formatTransactionId(transaction), { length: config.TABLE_ID_MAX_LENGTH })}
        </span>
      ),
    }, {
      className: styles.table__id,
      key: 'clientReferenceId',
      title: lang.get('transaction.list.clientReferenceId'),
      render: (_, transaction) => (
        <span title={formatTransactionClientReferenceId(transaction)}>
          {truncate(formatTransactionClientReferenceId(transaction), { length: config.TABLE_ID_MAX_LENGTH })}
        </span>
      ),
    }, {
      key: 'currency',
      title: lang.get('provider.dailySettlement.currency'),
      render: (_, transaction) => formatTransactionCurrency(transaction),
    }, {
      className: styles.table__balance,
      key: 'transactionAmount',
      title: lang.get('provider.dailySettlement.transactionAmount'),
      render: (_, transaction) => formatTransactionAmount(transaction),
    }, {
      className: styles.table__balance,
      key: 'transactionFees',
      title: lang.get('provider.dailySettlement.transactionFees'),
      render: (_, transaction) => formatTransactionFees(transaction),
    }, {
      className: styles.table__amount,
      key: 'netClientPayment',
      title: lang.get('provider.dailySettlement.netClientPayment'),
      render: (_, transaction) => formatNetClientPayment(transaction),
    }, {
      className: styles.table__balance,
      key: 'providerFees',
      title: lang.get('provider.dailySettlement.providerFees'),
      render: (_, transaction) => formatProviderFees(transaction),
    }, {
      className: styles.table__balance,
      key: 'feeIncome',
      title: lang.get('provider.dailySettlement.feeIncome'),
      render: (_, transaction) => formatFeeIncome(transaction),
    }, {
      key: 'destinationCurrency',
      title: lang.get('provider.dailySettlement.destinationCurrency'),
      render: (_, transaction) => formatTransactionDestinationCurrency(transaction),
    }, {
      className: styles.table__balance,
      key: 'destinationAmount',
      title: lang.get('provider.dailySettlement.destinationAmount'),
      render: (_, transaction) => formatTransactionDestinationAmount(transaction),
    },
  ];

  return (
    <Modal
      cancelText={lang.get('common.actions.close')}
      okButtonProps={{ hidden: true }}
      width="large"
      open={open}
      onCancel={handleClose}
    >
      <TableView
        title={lang.get('provider.dailySettlement.transactionsTitle', {
          currency: item.currency,
          date: moment(item.date).format(config.DATE_FORMAT),
          name: item.client.name,
          provider: item.provider.name,
        })}
        actions={(
          <TableView.ExportButton<Transaction>
            table={table}
            type="default"
            fileName={[
              EXPORT_FILE_NAME,
              item.client.name,
              item.currency,
              moment(item.date).format(config.DATE_RAW_FORMAT),
            ].filter(Boolean).join('-')}
            columns={exportColumns}
            fetchData={() => fetchPaginatedResponseFully(transactionService.getTransactions, transactionsParams)}
          />
        )}
      >
        <TableView.Table<Transaction>
          table={table}
          columns={tableColumns}
          rowKey={(transaction) => transaction.id}
          loading={transactionsQuery.isFetching}
        />
      </TableView>
    </Modal>
  );
};

export default SettlementTransactionsModal;
