import React, { FC, useState } from 'react';
import map from 'lodash/map';

import { businessAccountService } from 'services';
import { useLang, useModal } from 'hooks';
import { useBusinessAccountMembersQuery } from 'hooks/queries';
import { Plus } from 'components/icons';
import { Can, TableView } from 'components/layout';
import { Button, Modal, Table } from 'components/ui';
import { Uuid } from 'types/common';
import { ModalBaseProps, TableColumns } from 'types/components';
import { BusinessAccount, BusinessAccountMember } from 'types/models';

import styles from './styles.module.css';

import AddModal from './AddModal';
import ViewModal from './ViewModal';

type MembersModalProps = ModalBaseProps & {
  businessAccount: BusinessAccount;
};

const MembersModal: FC<MembersModalProps> = ({
  businessAccount,
  open,
  onClose,
}) => {
  const lang = useLang();
  const addModal = useModal();
  const viewModal = useModal();

  const [memberId, setMemberId] = useState<Uuid>();

  const membersQuery = useBusinessAccountMembersQuery(businessAccount.id);

  const handleAddClick = addModal.show;

  const handleViewClick = (member: BusinessAccountMember) => () => {
    setMemberId(member.id);

    viewModal.show();
  };

  const columns: TableColumns<BusinessAccountMember> = [
    {
      className: styles.table__email,
      key: 'email',
      title: lang.get('businessAccount.member.list.email'),
      render: (_, member) => <Table.Truncate width="middle">{member.email}</Table.Truncate>,
    }, {
      key: 'firstName',
      title: lang.get('businessAccount.member.list.firstName'),
      render: (_, member) => <Table.Truncate>{member.firstName || '-'}</Table.Truncate>,
    }, {
      key: 'lastName',
      title: lang.get('businessAccount.member.list.lastName'),
      render: (_, member) => <Table.Truncate>{member.lastName || '-'}</Table.Truncate>,
    }, {
      key: 'role',
      title: lang.get('businessAccount.member.list.roles'),
      render: (_, member) => map(member.roles, 'name').join(', ') || '-',
    },
  ];

  const member = membersQuery.data?.find((member) => member.id === memberId);

  return (
    <Modal
      cancelText={lang.get('common.actions.close')}
      okButtonProps={{ hidden: true }}
      width="large"
      open={open}
      onCancel={onClose}
    >
      <TableView
        title={lang.get('businessAccount.member.list.title')}
        caption={lang.choice('businessAccount.member.list.caption', membersQuery.data?.length ?? 0)}
        actions={(
          <Can permissions={businessAccountService.getBusinessAccountEditPermissions(businessAccount)}>
            <Button icon={<Plus />} onClick={handleAddClick}>
              {lang.get('businessAccount.member.list.add')}
            </Button>
          </Can>
        )}
      >

        <Table<BusinessAccountMember>
          columns={columns}
          dataSource={membersQuery.data ?? []}
          rowKey={(member) => member.id}
          loading={membersQuery.isFetching}
          clickable
          onRow={(member) => ({ onClick: handleViewClick(member) })}
        />

        <AddModal
          businessAccount={businessAccount}
          open={addModal.open}
          onClose={addModal.hide}
        />

        {member && (
          <ViewModal
            businessAccount={businessAccount}
            member={member}
            open={viewModal.open}
            onClose={viewModal.hide}
          />
        )}

      </TableView>
    </Modal>
  );
};

export default MembersModal;
