import React, { FC } from 'react';
import { Moment } from 'moment';
import { NoUndefinedRangeValueType } from 'rc-picker/lib/PickerInput/RangePicker';
import cx from 'classnames';

import { config, moment } from 'data';
import { Calendar } from 'components/icons';
import { DateRangePickerProps } from 'types/components';

import { CustomDatePicker } from '../DatePicker';

import styles from './styles.module.css';

const DateRangePicker: FC<DateRangePickerProps> = ({
  className,
  format = config.DATE_FORMAT,
  size = 'large',
  separator = '–',
  suffixIcon = <Calendar />,
  value,
  inputReadOnly = true,
  onChange,
  ...props
}) => {
  const handleChange = (dates: NoUndefinedRangeValueType<Moment> | null, dateStrings: [string, string]) => {
    if (dates) {
      // Shift values to the start/end of day
      dates[0] = dates[0] && moment(dates[0]).startOf('day');
      dates[1] = dates[1] && moment(dates[1]).endOf('day');
    }

    onChange?.(dates, dateStrings);
  };

  if (value) {
    // Convert string values to moment
    value[0] = value[0] && moment(value[0]);
    value[1] = value[1] && moment(value[1]);
  }

  return (
    <CustomDatePicker.RangePicker
      className={cx(styles.root, className)}
      format={format}
      size={size}
      separator={separator}
      suffixIcon={suffixIcon}
      value={value}
      inputReadOnly={inputReadOnly}
      onChange={handleChange}
      {...props}
    />
  );
};

export default DateRangePicker;
