import React, { FC } from 'react';
import { PresetStatusColorType } from 'antd/es/_util/colors';

import { useLang } from 'hooks';
import { Tag } from 'components/ui';
import { Override } from 'types/common';
import { TagProps } from 'types/components';
import { BusinessApplicationStatus as EBusinessApplicationStatus } from 'types/models';

const statusColors: Record<EBusinessApplicationStatus, PresetStatusColorType> = {
  [EBusinessApplicationStatus.APPROVED]: 'success',
  [EBusinessApplicationStatus.ARCHIVED]: 'error',
  [EBusinessApplicationStatus.DECLINED]: 'error',
  [EBusinessApplicationStatus.FAILED]: 'error',
  [EBusinessApplicationStatus.IN_PROGRESS]: 'processing',
  [EBusinessApplicationStatus.NEW]: 'warning',
  [EBusinessApplicationStatus.PASSED]: 'success',
  [EBusinessApplicationStatus.REVIEW]: 'warning',
};

type BusinessAccountStatusProps = Override<Omit<TagProps, 'color' | 'children'>, {
  status: EBusinessApplicationStatus;
}>;

const BusinessApplicationStatus: FC<BusinessAccountStatusProps> = ({ status, ...props }) => {
  const lang = useLang();

  return (
    <Tag color={statusColors[status]} {...props}>
      {lang.get(`businessApplication.statuses.${status.toLowerCase()}`)}
    </Tag>
  );
};

export default BusinessApplicationStatus;
