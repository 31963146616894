import React, { FC } from 'react';
import { PresetStatusColorType } from 'antd/es/_util/colors';

import { useLang } from 'hooks';
import { Tag } from 'components/ui';
import { Override } from 'types/common';
import { TagProps } from 'types/components';
import { TransactionStatus as ETransactionStatus } from 'types/models';

const statusColors: Record<ETransactionStatus, PresetStatusColorType> = {
  [ETransactionStatus.CANCELED]: 'default',
  [ETransactionStatus.COMPLETED]: 'success',
  [ETransactionStatus.FAILED]: 'error',
  [ETransactionStatus.INSUFFICIENT_FUNDS]: 'error',
  [ETransactionStatus.PENDING]: 'processing',
};

type TransactionStatusProps = Override<Omit<TagProps, 'color' | 'children'>, {
  status: ETransactionStatus;
}>;

const TransactionStatus: FC<TransactionStatusProps> = ({ status, ...props }) => {
  const lang = useLang();

  return (
    <Tag color={statusColors[status]} {...props}>
      {lang.get(`transaction.statuses.${status.toLowerCase()}`)}
    </Tag>
  );
};

export default TransactionStatus;
