import React, { FC } from 'react';
import { Typography } from 'antd';
import { LinkProps } from 'antd/es/typography/Link';

const Link: FC<LinkProps> = ({
  href,
  target,
  ...props
}) => {
  const external = href?.startsWith('http:')
    || href?.startsWith('https:')
    || href?.startsWith('tel:')
    || href?.startsWith('mailto:');

  return (
    <Typography.Link
      href={href}
      target={external ? '_blank' : target}
      {...props}
    />
  );
};

export default Link;
