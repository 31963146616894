export enum ContentType {
  JSON = 'application/json',
  MULTIPART = 'multipart/form-data',
}

export enum HttpStatus {
  OK = 200,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  INTERNAL_SERVER_ERROR = 500,
}

export type Nullable<Type> = Type | null;

export type Nullish<Type> = Type | null | undefined;

export type Override<Type, Overrides> = Omit<Type, keyof Overrides> & Overrides;

export type PrimitiveType = boolean | number | string | undefined | null;

export type Uuid = string;

export type XlsxHeader = string;

export type XlsxRow = PrimitiveType[];
