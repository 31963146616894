import React, { FC } from 'react';

import { BaseLayout } from 'layouts';
import { BaseLayoutProps } from 'types/layouts';

import Content from './Content';
import Header from './Header';
import Layout from './Layout';

type DashboardLayoutProps = BaseLayoutProps & {
  showBreadcrumbs?: boolean;
};

const DashboardLayout: FC<DashboardLayoutProps> = ({
  children,
  showBreadcrumbs = true,
  ...props
}) => (
  <BaseLayout {...props}>
    <Layout>
      <Header />
      <Content showBreadcrumbs={showBreadcrumbs}>
        {children}
      </Content>
    </Layout>
  </BaseLayout>
);

export default DashboardLayout;
