import React, { FC, useEffect } from 'react';

import { config } from 'data';
import { userService } from 'services';
import { useAuth, useLang, useModal, useMutation, useQueryInvalidate } from 'hooks';
import { Trash } from 'components/icons';
import { Button, Descriptions, Form, Link, Modal, PopconfirmButton } from 'components/ui';
import { ModalBaseProps } from 'types/components';
import { User, UserPermission } from 'types/models';

import RolesModal from './RolesModal';

type ViewUserModalProps = ModalBaseProps & {
  user: User;
  onDelete: VoidFunction;
};

const ViewUserModal: FC<ViewUserModalProps> = ({
  user,
  open,
  onClose,
  onDelete,
}) => {
  const auth = useAuth();
  const lang = useLang();
  const rolesModal = useModal();
  const queryInvalidate = useQueryInvalidate();

  const invalidateUserQuery = async () => {
    await queryInvalidate([config.USERS_QUERY_KEY]);
    await queryInvalidate([config.USER_ROLES_QUERY_KEY]);
  };

  const deleteUserMutation = useMutation({
    mutationFn: () => userService.deleteUser(user.id),
    onSuccess: invalidateUserQuery,
    successNotification: lang.get('user.modal.deleteSuccess', { email: user.email }),
  });

  const handleDelete = async () => {
    await deleteUserMutation.mutateAsync();

    onDelete();
    onClose();
  };

  useEffect(() => {
    if (!open) {
      rolesModal.hide();
    }
  }, [open, rolesModal]);

  const canEdit = auth.can(UserPermission.USERS_UPDATE);
  const isOwned = user.id === auth.profile?.id;
  const hasActions = canEdit && !isOwned;

  return (
    <Modal
      title={lang.get('user.modal.viewTitle', { email: user.email })}
      caption={lang.get('user.modal.viewCaption')}
      cancelText={lang.get('common.actions.close')}
      okButtonProps={{ hidden: true }}
      extraActions={hasActions && (
        <PopconfirmButton
          title={lang.get('user.modal.deleteTitle')}
          icon={<Trash />}
          danger
          loading={deleteUserMutation.isPending}
          onConfirm={handleDelete}
        >
          {lang.get('common.actions.delete')}
        </PopconfirmButton>
      )}
      open={open}
      onCancel={onClose}
    >

      <Form.ActionsItem
        label={lang.choice('user.modal.rolesLabel', user.roles.length ?? 0)}
        hidden={!hasActions}
      >
        <Button type="default" onClick={rolesModal.show}>
          {lang.get('user.modal.rolesButton')}
        </Button>
      </Form.ActionsItem>

      <Form.Divider hidden={!hasActions} />

      <Descriptions
        items={[{
          label: lang.get('common.form.firstName.label'),
          children: user.firstName ?? '-',
        }, {
          label: lang.get('common.form.lastName.label'),
          children: user.lastName ?? '-',
        }, {
          label: lang.get('common.form.email.label'),
          children: <Link href={`mailto:${user.email}`}>{user.email}</Link>,
        }, {
          label: lang.get('common.form.phone.label'),
          children: user.phone
            ? <Link href={`tel:${user.phone}`}>{user.phone}</Link>
            : '-',
        }]}
      />

      <RolesModal
        user={user}
        open={rolesModal.open}
        onClose={rolesModal.hide}
      />

    </Modal>
  );
};

export default ViewUserModal;
