import React, { ReactNode } from 'react';
import { useMatches } from 'react-router-dom';

import { Breadcrumb as BaseBreadcrumb } from 'components/ui';

import styles from './styles.module.css';

type MatchHandle = {
  crumb?: (data: unknown) => ReactNode;
};

const Breadcrumbs = () => {
  const matches = useMatches();

  const crumbs = matches.filter((match) => {
    const handle = match.handle as MatchHandle | undefined;

    return Boolean(handle?.crumb);
  }).map((match) => {
    const handle = match.handle as Required<MatchHandle>;

    return handle.crumb(match.data);
  });

  return (
    <BaseBreadcrumb
      className={styles.root}
      items={crumbs.map((crumb) => ({ title: crumb }))}
    />
  );
};

export default Breadcrumbs;
