import { FC, ReactNode } from 'react';

import { useAuth } from 'hooks';
import { UserPermission } from 'types/models';

type CanProps = {
  permissions: UserPermission[];
  fallback?: ReactNode;
  children: ReactNode;
};

const Can: FC<CanProps> = ({
  permissions,
  fallback,
  children,
}) => {
  const auth = useAuth();

  return auth.can(...permissions) ? children : fallback;
};

export default Can;
