import React, { FC } from 'react';

import { useLang } from 'hooks';
import { Tag } from 'components/ui';
import { Override } from 'types/common';
import { TagProps } from 'types/components';
import { BusinessAccountStatus as EBusinessAccountStatus } from 'types/models';

type BusinessAccountStatusProps = Override<Omit<TagProps, 'color' | 'children'>, {
  status: EBusinessAccountStatus;
}>;

const BusinessAccountStatus: FC<BusinessAccountStatusProps> = ({ status, ...props }) => {
  const lang = useLang();

  return (
    <Tag color={status === EBusinessAccountStatus.ACTIVE ? 'success' : 'default'} {...props}>
      {lang.get(`businessAccount.statuses.${status.toLowerCase()}`)}
    </Tag>
  );
};

export default BusinessAccountStatus;
