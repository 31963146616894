import { axios } from 'data';
import { Uuid } from 'types/common';

import {
  ReportBusinessAccountsBalancesItem,
  ReportBusinessAccountsDailyBalanceItem,
  ReportBusinessAccountsDailyRevenueItem,
  ReportDailyBalanceItem,
  ReportDailyRemittanceItem,
  ReportDailyTransactionItem,
  ReportProvidersDailyBalanceItem,
  ReportProvidersDailySettlementItem,
} from 'types/models';

import {
  ReportBusinessAccountsBalancesParams,
  ReportBusinessAccountsBalancesResponse,
  ReportBusinessAccountsDailyBalanceParams,
  ReportBusinessAccountsDailyBalanceResponse,
  ReportBusinessAccountsDailyRevenueParams,
  ReportBusinessAccountsDailyRevenueResponse,
  ReportDailyBalanceParams,
  ReportDailyBalanceResponse,
  ReportDailyRemittanceParams,
  ReportDailyRemittanceResponse,
  ReportDailyTransactionsParams,
  ReportDailyTransactionsResponse,
  ReportProvidersDailyBalanceParams,
  ReportProvidersDailyBalanceResponse,
  ReportProvidersDailySettlementParams,
  ReportProvidersDailySettlementResponse,
  ReportProvidersDailySettlementSettleTransactions,
} from 'types/services';

import { preparePaginatableParams, preparePaginatedData, prepareTimezonableParams } from './helpers';

const getBusinessAccountsBalances = async (params: ReportBusinessAccountsBalancesParams = {}) => {
  params = preparePaginatableParams(params);

  const response = await axios.get<ReportBusinessAccountsBalancesResponse>('/payments/admin/report/balance', { params });

  return preparePaginatedData<ReportBusinessAccountsBalancesItem>(response.data);
};

const getBusinessAccountsDailyBalance = async (params: ReportBusinessAccountsDailyBalanceParams) => {
  params = prepareTimezonableParams(params);
  params = preparePaginatableParams(params);

  const response = await axios.get<ReportBusinessAccountsDailyBalanceResponse>('/payments/admin/report/client/balance', { params });

  return preparePaginatedData<ReportBusinessAccountsDailyBalanceItem>(response.data);
};

const getBusinessAccountsDailyRevenue = async (params: ReportBusinessAccountsDailyRevenueParams) => {
  params = prepareTimezonableParams(params);
  params = preparePaginatableParams(params);

  const response = await axios.get<ReportBusinessAccountsDailyRevenueResponse>('/payments/admin/report/client/revenue', { params });

  return preparePaginatedData<ReportBusinessAccountsDailyRevenueItem>(response.data);
};

const getDailyBalance = async (params: ReportDailyBalanceParams) => {
  params = prepareTimezonableParams(params);
  params = preparePaginatableParams(params);

  const response = await axios.get<ReportDailyBalanceResponse>('/payments/admin/report/transaction/balance', { params });

  return preparePaginatedData<ReportDailyBalanceItem>(response.data);
};

const getDailyRemittance = async (params: ReportDailyRemittanceParams) => {
  params = prepareTimezonableParams(params);
  params = preparePaginatableParams(params);

  const endpoint = params.clientIds ? '/payments/admin/report/client' : '/payments/admin/report';
  const response = await axios.get<ReportDailyRemittanceResponse>(endpoint, { params });

  return preparePaginatedData<ReportDailyRemittanceItem>(response.data);
};

const getDailyTransactions = async (params: ReportDailyTransactionsParams) => {
  params = prepareTimezonableParams(params);
  params = preparePaginatableParams(params);

  const response = await axios.get<ReportDailyTransactionsResponse>('/payments/admin/report/transaction', { params });

  return preparePaginatedData<ReportDailyTransactionItem>(response.data);
};

const getProvidersDailyBalance = async (params: ReportProvidersDailyBalanceParams) => {
  params = prepareTimezonableParams(params);
  params = preparePaginatableParams(params);

  const response = await axios.get<ReportProvidersDailyBalanceResponse>('/payments/admin/report/provider/balance', { params });

  return preparePaginatedData<ReportProvidersDailyBalanceItem>(response.data);
};

const getProvidersDailySettlement = async (params: ReportProvidersDailySettlementParams) => {
  params = prepareTimezonableParams(params);
  params = preparePaginatableParams(params);

  const response = await axios.get<ReportProvidersDailySettlementResponse>('/payments/admin/report/provider/settlement', { params });

  return preparePaginatedData<ReportProvidersDailySettlementItem>(response.data);
};

const settleProvidersDailySettlementTransactions = async (transactionIds: Uuid[]) => {
  const params: ReportProvidersDailySettlementSettleTransactions = {
    transactionIds,
    settle: true,
  };

  await axios.post<never>('/payments/admin/report/provider/settlement', params);
};

const unsettleProvidersDailySettlementTransactions = async (transactionIds: Uuid[]) => {
  const params: ReportProvidersDailySettlementSettleTransactions = {
    transactionIds,
    settle: false,
  };

  await axios.post<never>('/payments/admin/report/provider/settlement', params);
};

const reportService = {
  getBusinessAccountsBalances,
  getBusinessAccountsDailyBalance,
  getBusinessAccountsDailyRevenue,
  getDailyBalance,
  getDailyRemittance,
  getDailyTransactions,
  getProvidersDailyBalance,
  getProvidersDailySettlement,
  settleProvidersDailySettlementTransactions,
  unsettleProvidersDailySettlementTransactions,
};

export default reportService;
