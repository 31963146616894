import React, { FC } from 'react';
import { Drawer as BaseDrawer, DrawerProps as BaseDrawerProps } from 'antd';
import cx from 'classnames';

import { Override } from 'types/common';

import styles from './styles.module.css';

type DrawerProps = Override<BaseDrawerProps, {
  title: BaseDrawerProps['title'];
}>;

const Drawer: FC<DrawerProps> = ({
  className,
  closable = false,
  ...props
}) => (
  <BaseDrawer
    className={cx(styles.root, className)}
    closable={closable}
    {...props}
  />
);

export default Drawer;
