import React, { FC } from 'react';

import { useLang } from 'hooks';
import { useAllBasicProvidersQuery } from 'hooks/queries';
import { Select } from 'components/ui';
import { SelectProps } from 'types/components';

type ProviderSelectProps = Omit<SelectProps, 'options' | 'loading'>;

const ProviderSelect: FC<ProviderSelectProps> = (props) => {
  const lang = useLang();

  const providersQuery = useAllBasicProvidersQuery();

  return (
    <Select
      placeholder={lang.get('common.form.provider.placeholder')}
      options={providersQuery.data?.map((provider) => ({
        value: provider.id,
        label: provider.name,
      }))}
      loading={providersQuery.isFetching}
      {...props}
    />
  );
};

export default ProviderSelect;
