import { config } from 'data';
import { transactionBundleService } from 'services';
import { useQuery } from 'hooks';
import { Uuid } from 'types/common';

const useTransactionBundleQuery = (id?: Uuid) => useQuery({
  queryKey: [config.TRANSACTION_BUNDLE_QUERY_KEY, id],
  queryFn: () => id ? transactionBundleService.getBundle(id) : null,
});

export default useTransactionBundleQuery;
