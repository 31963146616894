import React, { FC, useEffect } from 'react';

import { config, yup } from 'data';
import { businessAccountService } from 'services';
import { useAuth, useForm, useLang, useMutation, useQueryInvalidate } from 'hooks';
import { useBusinessAccountDetailsQuery } from 'hooks/queries';
import { Form, Input, Modal, PopconfirmButton } from 'components/ui';
import { Uuid } from 'types/common';
import { ModalBaseProps } from 'types/components';
import { BusinessAccount } from 'types/models';
import { BusinessAccountUpdateDetailsParams } from 'types/services';

type FormValues = Pick<BusinessAccountUpdateDetailsParams, 'webhookUrl'> & {
  id?: Uuid;
};

const initialValues: FormValues = {
  webhookUrl: null,
};

const validationSchema = yup.object({
  webhookUrl: yup.string().notRequired().website().default(initialValues.webhookUrl),
});

type ApiModalProps = ModalBaseProps & {
  businessAccount: BusinessAccount;
};

const ApiModal: FC<ApiModalProps> = ({
  businessAccount,
  open,
  onClose,
}) => {
  const auth = useAuth();
  const form = useForm<FormValues>();
  const lang = useLang();
  const queryInvalidate = useQueryInvalidate();

  const businessAccountDetailsQuery = useBusinessAccountDetailsQuery(businessAccount.id);

  const invalidateBusinessAccountQueries = async () => {
    await queryInvalidate([config.BUSINESS_ACCOUNT_DETAILS_QUERY_KEY, businessAccount.id]);
  };

  const updateBusinessAccountDetailsMutation = useMutation({
    mutationFn: (values: FormValues) => businessAccountService.updateBusinessAccountDetails(businessAccount.id, values),
    onSuccess: invalidateBusinessAccountQueries,
    successNotification: lang.get('businessAccount.api.modal.success', { name: businessAccount.name }),
  });

  const resetBusinessAccountApiCredentialsMutation = useMutation({
    mutationFn: () => businessAccountService.resetBusinessAccountApiCredentials(businessAccount.id),
    onSuccess: invalidateBusinessAccountQueries,
    successNotification: lang.get('businessAccount.api.modal.resetCredentials.success', { name: businessAccount.name }),
  });

  const handleSubmit = async (values: FormValues) => {
    await updateBusinessAccountDetailsMutation.mutateAsync(values);

    onClose();
  };

  useEffect(() => {
    if (open && businessAccountDetailsQuery.data) {
      form.setFieldsValue(businessAccountDetailsQuery.data);
    }
  }, [open, form, businessAccountDetailsQuery.data]);

  const canEdit = auth.can(...businessAccountService.getBusinessAccountEditPermissions(businessAccount));

  return (
    <Modal
      title={lang.get('businessAccount.api.modal.title', { name: businessAccount.name })}
      caption={lang.get('businessAccount.api.modal.caption')}
      okText={lang.get('common.actions.save')}
      cancelText={!canEdit ? lang.get('common.actions.close') : null}
      okButtonProps={{ hidden: !canEdit }}
      width="small"
      open={open}
      confirmLoading={updateBusinessAccountDetailsMutation.isPending}
      onOk={form.submit}
      onCancel={onClose}
    >
      <Form
        form={form}
        initialValues={initialValues}
        validationSchema={validationSchema}
        disabled={!canEdit}
        onFinish={handleSubmit}
      >

        <Form.ActionsItem hidden={!canEdit}>
          <PopconfirmButton
            title={lang.get('businessAccount.api.modal.resetCredentials.title')}
            description={lang.get('businessAccount.api.modal.resetCredentials.caption')}
            loading={resetBusinessAccountApiCredentialsMutation.isPending}
            onConfirm={() => resetBusinessAccountApiCredentialsMutation.mutateAsync()}
          >
            {lang.get('businessAccount.api.modal.resetCredentials.button')}
          </PopconfirmButton>
        </Form.ActionsItem>

        <Form.Divider hidden={!canEdit} />

        <Form.Item name="id" label={lang.get('businessAccount.api.modal.id.label')}>
          <Input placeholder={lang.get('businessAccount.api.modal.id.placeholder')} readOnly />
        </Form.Item>
        <Form.Item name="webhookUrl" label={lang.get('businessAccount.api.modal.webhookUrl.label')}>
          <Input type="url" placeholder={lang.get('businessAccount.api.modal.webhookUrl.placeholder')} />
        </Form.Item>

      </Form>
    </Modal>
  );
};

export default ApiModal;
