import { useEffect } from 'react';

import { QueryInstance, TableInstance } from 'types/hooks';

const useTableQuery = <DataType>(
  table: TableInstance<DataType>,
  query: QueryInstance<DataType>,
) => {
  useEffect(() => {
    if (query.data) {
      const { list, page, size, total } = query.data;

      table.setData(list);

      table.setPagination({
        current: page,
        pageSize: size,
        total: total,
      });
    }
  }, [table, query.data]);
};

export default useTableQuery;
