import { config } from 'data';
import { transactionBundleService } from 'services';
import { useQuery } from 'hooks';
import { BundlesParams } from 'types/services';

const useTransactionBundlesQuery = (params?: BundlesParams) => useQuery({
  queryKey: [config.TRANSACTION_BUNDLES_QUERY_KEY, params],
  queryFn: () => transactionBundleService.getBundles(params),
});

export default useTransactionBundlesQuery;
