import React, { FC, ReactNode } from 'react';

import { Breadcrumbs } from 'components/layout';
import { Container, Layout } from 'components/ui';

import styles from './styles.module.css';

type ContentProps = {
  children: ReactNode;
  showBreadcrumbs?: boolean;
};

const Content: FC<ContentProps> = ({ children, showBreadcrumbs }) => (
  <Layout.Content className={styles.content}>
    <Container className={styles.content__container}>
      {showBreadcrumbs && <Breadcrumbs />}
      {children}
    </Container>
  </Layout.Content>
);

export default Content;
