import React, { FC } from 'react';

import { useBusinessAccountQuery } from 'hooks/queries';
import { ModalBaseProps } from 'types/components';
import { BusinessAccount, Transaction, TransactionStatus, TransactionType } from 'types/models';

import EditAdjustmentModal from './EditAdjustmentModal';
import ViewAdjustmentModal from './ViewAdjustmentModal';
import ViewModal from './ViewModal';

type TransactionModalProps = ModalBaseProps & {
  businessAccount?: BusinessAccount;
  transaction?: Transaction;
};

const TransactionModal: FC<TransactionModalProps> = ({
  businessAccount,
  transaction,
  open,
  onClose,
}) => {
  const businessAccountQuery = useBusinessAccountQuery(businessAccount?.id || transaction?.client.id);

  const currentBusinessAccount = businessAccountQuery.data;

  if (transaction && transaction.transactionType !== TransactionType.ADJUSTMENT) {
    return currentBusinessAccount && (
      <ViewModal
        businessAccount={currentBusinessAccount}
        transaction={transaction}
        open={open}
        onClose={onClose}
      />
    );
  }

  if (!transaction || transaction.status === TransactionStatus.PENDING) {
    return (
      <EditAdjustmentModal
        businessAccount={currentBusinessAccount ?? undefined}
        transaction={transaction}
        open={open}
        onClose={onClose}
      />
    );
  }

  return currentBusinessAccount && (
    <ViewAdjustmentModal
      businessAccount={currentBusinessAccount}
      transaction={transaction}
      open={open}
      onClose={onClose}
    />
  );
};

export default TransactionModal;
