import { config } from 'data';
import { profileService } from 'services';
import { fetchPaginatedResponseFully } from 'services/helpers';
import { useQuery } from 'hooks';

const useAllProfileTopicsQuery = () => useQuery({
  queryKey: [config.PROFILE_TOPICS_QUERY_KEY, 'all'],
  queryFn: () => fetchPaginatedResponseFully(profileService.getTopics),
});

export default useAllProfileTopicsQuery;
