import { axios } from 'data';
import { Uuid } from 'types/common';
import { Topic, User, UserRole } from 'types/models';

import {
  UserCreateParams,
  UserRoleParams,
  UserRolesParams,
  UserRolesResponse,
  UsersParams,
  UsersResponse,
  UserTopicsParams,
  UserTopicsResponse,
  UserUpdateRolesParams,
} from 'types/services';

import { preparePaginatableParams, preparePaginatedData } from './helpers';

const getUsers = async (params: UsersParams = {}) => {
  const response = await axios.get<UsersResponse>('/core/admin/user', {
    params: preparePaginatableParams(params),
  });

  return preparePaginatedData<User>(response.data);
};

const getUser = async (id: Uuid) => {
  const response = await axios.get<User>(`/core/admin/user/${id}`);

  return response.data;
};

const createUser = async (params: UserCreateParams) => {
  await axios.post<never>('/core/admin/user', params);
};

const updateUserRoles = async (id: Uuid, params: UserUpdateRolesParams) => {
  await axios.put<never>(`/core/admin/user/${id}/roles`, params);
};

const deleteUser = async (id: Uuid) => {
  await axios.delete<never>(`/core/admin/user/${id}`);
};

const getRoles = async (params: UserRolesParams = {}) => {
  const response = await axios.get<UserRolesResponse>('/core/role/admin', {
    params: preparePaginatableParams(params),
  });

  return preparePaginatedData<UserRole>(response.data);
};

const getRole = async (id: Uuid) => {
  const response = await axios.get<UserRole>(`/core/role/admin/${id}`);

  return response.data;
};

const createRole = async (params: UserRoleParams) => {
  await axios.post<never>('/core/role/admin', params);
};

const updateRole = async (id: Uuid, params: UserRoleParams) => {
  await axios.put<never>(`/core/role/admin/${id}`, params);
};

const deleteRole = async (id: Uuid) => {
  await axios.delete<never>(`/core/role/admin/${id}`);
};

const getTopics = async (params: UserTopicsParams = {}) => {
  const response = await axios.get<UserTopicsResponse>('/payments/admin/user/topic', {
    params: preparePaginatableParams(params),
  });

  return preparePaginatedData<Topic>(response.data);
};

const userService = {
  getUsers,
  getUser,
  createUser,
  updateUserRoles,
  deleteUser,
  getRoles,
  getRole,
  createRole,
  updateRole,
  deleteRole,
  getTopics,
};

export default userService;
