import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { url } from 'helpers';
import { useLang, useModal } from 'hooks';
import { ChevronDown, PieChart, User } from 'components/icons';
import { BusinessAccountBalance, BusinessAccountIcon, Can, CardView } from 'components/layout';
import { Button, Dropdown, Flex } from 'components/ui';
import { BusinessAccountExtended, BusinessAccountType, UserPermission } from 'types/models';

import Modal from './Modal';

type BusinessAccountViewProps = {
  businessAccount: BusinessAccountExtended;
};

const BusinessAccountView: FC<BusinessAccountViewProps> = ({ businessAccount }) => {
  const lang = useLang();
  const navigate = useNavigate();
  const modal = useModal();

  const isIndividual = businessAccount.type === BusinessAccountType.INDIVIDUAL;

  return (
    <>

      <CardView
        icon={<BusinessAccountIcon businessAccountType={businessAccount.type} size="large" />}
        title={
          isIndividual
            ? lang.get('businessAccount.single.individualTitle', { name: businessAccount.name })
            : lang.get('businessAccount.single.title', { name: businessAccount.name })
        }
        caption={<BusinessAccountBalance businessAccount={businessAccount} />}
        actions={(
          <Flex gap="small" wrap="wrap">
            <Button type="default" icon={<User />} onClick={modal.show}>
              {lang.get('businessAccount.single.profile')}
            </Button>
            <Can permissions={isIndividual ? [UserPermission.INDIVIDUALS_REPORTS_VIEW] : [UserPermission.BA_REPORTS_VIEW]}>
              <Dropdown
                menu={{
                  items: [{
                    key: 'dailyBalance',
                    label: lang.get('common.menu.dailyBalance'),
                    onClick: () => {
                      navigate(
                        isIndividual
                          ? url.toIndividualDailyBalance(businessAccount.id)
                          : url.toBusinessAccountDailyBalance(businessAccount.id),
                      );
                    },
                  }, {
                    key: 'dailyRemittance',
                    label: lang.get('common.menu.dailyRemittance'),
                    onClick: () => {
                      navigate(
                        isIndividual
                          ? url.toIndividualDailyRemittance(businessAccount.id)
                          : url.toBusinessAccountDailyRemittance(businessAccount.id),
                      );
                    },
                  }, {
                    key: 'dailyTransactions',
                    label: lang.get('common.menu.dailyTransactions'),
                    onClick: () => {
                      navigate(
                        isIndividual
                          ? url.toIndividualDailyTransactions(businessAccount.id)
                          : url.toBusinessAccountDailyTransactions(businessAccount.id),
                      );
                    },
                  }],
                }}
              >
                <Button type="default" icon={<PieChart />}>
                  {lang.get('businessAccount.single.reports')}
                  <ChevronDown />
                </Button>
              </Dropdown>
            </Can>
          </Flex>
        )}
      />

      <Modal
        businessAccount={businessAccount}
        open={modal.open}
        onClose={modal.hide}
      />

    </>
  );
};

export default BusinessAccountView;
