import { config } from 'data';
import { businessApplicationService } from 'services';
import { useQuery } from 'hooks';
import { Uuid } from 'types/common';

const useBusinessApplicationDetailsQuery = (id?: Uuid) => useQuery({
  queryKey: [config.BUSINESS_APPLICATION_DETAILS_QUERY_KEY, id],
  queryFn: () => id ? businessApplicationService.getBusinessApplicationDetails(id) : null,
});

export default useBusinessApplicationDetailsQuery;
