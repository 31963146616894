import React, { FC } from 'react';

import { formatter } from 'helpers';
import { useLang } from 'hooks';
import { Flex } from 'components/ui';
import { CurrencyCode } from 'types/models';

import styles from './styles.module.css';

type BalanceInfoProps = {
  currentAmount: number;
  incomingPendingAmount: number;
  outgoingPendingAmount: number;
  currency: CurrencyCode;
};

const BalanceInfo: FC<BalanceInfoProps> = ({
  currentAmount,
  incomingPendingAmount,
  outgoingPendingAmount,
  currency,
}) => {
  const lang = useLang();

  return (
    <Flex className={styles.root} align="center" wrap="wrap">
      <span className={styles.item}>
        {lang.get('businessAccount.balance.current')}
        <strong>{formatter.formatCurrency(currentAmount, currency)}</strong>
      </span>
      <span className={styles.separator} />
      <span className={styles.item}>
        <span>{formatter.formatCurrency(outgoingPendingAmount, currency)}</span>
        {lang.get('businessAccount.balance.outgoingPending')}
      </span>
      <span className={styles.separator} />
      <span className={styles.item}>
        <span>{formatter.formatCurrency(incomingPendingAmount, currency)}</span>
        {lang.get('businessAccount.balance.incomingPending')}
      </span>
    </Flex>
  );
};

export default BalanceInfo;
