import React, { useState } from 'react';

import { config } from 'data';
import { formatter } from 'helpers';
import { useLang, useQueryModal, useTable, useTableQuery } from 'hooks';
import { useProviderQuery, useProvidersQuery } from 'hooks/queries';
import { DashboardLayout } from 'layouts';
import { Warning } from 'components/icons';
import { ActivityStatus, TableView } from 'components/layout';
import { Form, Select, Table, Tooltip } from 'components/ui';
import { Uuid } from 'types/common';
import { TableColumns } from 'types/components';
import { ActivityStatus as TActivityStatus, ProviderSimple } from 'types/models';

import styles from './styles.module.css';

import Modal from './Modal';

type TableParams = {
  search?: string;
  status?: TActivityStatus;
};

const initialTableParams: TableParams = {
  //
};

const ProvidersPage = () => {
  const lang = useLang();
  const table = useTable<ProviderSimple, TableParams>([config.PROVIDERS_QUERY_KEY], initialTableParams);

  const [providerId, setProviderId] = useState<Uuid>();

  const modal = useQueryModal(config.PROVIDERS_QUERY_KEY, setProviderId);

  const providerQuery = useProviderQuery(providerId);

  const providersQuery = useProvidersQuery({
    page: table.page,
    search: table.params.search || undefined,
    active: table.params.status ? table.params.status === TActivityStatus.ACTIVE : undefined,
  });

  const handleViewClick = (provider: ProviderSimple) => () => {
    modal.show(provider.id);
  };

  useTableQuery(table, providersQuery);

  const columns: TableColumns<ProviderSimple> = [
    {
      className: styles.table__name,
      key: 'name',
      title: lang.get('provider.list.name'),
      render: (_, provider) => <Table.Truncate>{provider.name}</Table.Truncate>,
    }, {
      className: styles.table__date,
      key: 'date',
      title: lang.get('provider.list.date'),
      render: (_, provider) => formatter.formatDateTime(provider.updatedAt ?? provider.createdAt),
    }, {
      className: styles.table__balance,
      key: 'balance',
      title: lang.get('provider.list.balance'),
      render: (_, provider) => {
        if (provider.balance) {
          return (
            <div className={styles.balance}>
              <div className={styles.balance__item}>
                <strong>{formatter.formatCurrency(provider.balance.available, provider.balance.currency)}</strong>
              </div>
              {provider.balance.pending !== null && (
                <div className={styles.balance__item}>
                  <span>{formatter.formatCurrency(provider.balance.pending, provider.balance.currency)}</span>
                  {lang.get('provider.balance.pending')}
                </div>
              )}
              {provider.balance.total !== null && (
                <div className={styles.balance__item}>
                  <span>{formatter.formatCurrency(provider.balance.total, provider.balance.currency)}</span>
                  {lang.get('provider.balance.total')}
                </div>
              )}
            </div>
          );
        }

        return (
          <Tooltip title={lang.get('provider.list.balanceFailed')}>
            <Warning />
          </Tooltip>
        );
      },
    }, {
      key: 'status',
      title: lang.get('provider.list.status'),
      render: (_, provider) => <ActivityStatus active={provider.active} />,
    },
  ];

  return (
    <DashboardLayout title={lang.get('provider.list.title')}>
      <TableView title={lang.get('provider.list.title')}>

        <TableView.Filters<TableParams>
          initialValues={initialTableParams}
          values={table.params}
          withSearch
          inline
          onSubmit={table.setParams}
        >
          <Form.Item name="status">
            <Select
              placeholder={lang.get('common.activityStatuses.all')}
              options={Object.values(TActivityStatus).map((status) => ({
                value: status,
                label: lang.get(`common.activityStatuses.${status.toLowerCase()}`),
              }))}
              allowClear
              popupMatchSelectWidth={false}
            />
          </Form.Item>
        </TableView.Filters>

        <TableView.Table<ProviderSimple>
          table={table}
          columns={columns}
          rowKey={(provider) => provider.id}
          loading={providersQuery.isFetching}
          clickable
          onRow={(provider) => ({ onClick: handleViewClick(provider) })}
        />

        {providerQuery.data && (
          <Modal
            provider={providerQuery.data}
            open={!providerQuery.isFetching && modal.open}
            onClose={modal.hide}
          />
        )}

      </TableView>
    </DashboardLayout>
  );
};

export default ProvidersPage;
