import React, { FC } from 'react';

import { IconProps } from 'types/components';

import Icon from './Icon';

const Warning: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path d="M8.77 4.19 2.69 14.34a1.45 1.45 0 0 0 .53 2 1.53 1.53 0 0 0 .7.19h12.16a1.44 1.44 0 0 0 1.42-1.45 1.48 1.48 0 0 0-.19-.71L11.23 4.19a1.44 1.44 0 0 0-2-.48 1.5 1.5 0 0 0-.46.48ZM10 7.79v3m0 3h0" />
  </Icon>
);

export default Warning;
