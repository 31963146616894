import React, { FC } from 'react';

import { useLang } from 'hooks';
import { Modal, Paragraph } from 'components/ui';
import { ModalBaseProps } from 'types/components';
import { Transaction } from 'types/models';

import styles from './styles.module.css';

type TransactionDetailsModalProps = ModalBaseProps & {
  transaction: Transaction;
};

const TransactionDetailsModal: FC<TransactionDetailsModalProps> = ({
  transaction,
  open,
  onClose,
}) => {
  const lang = useLang();

  return (
    <Modal
      title={lang.get('transaction.detailsModal.title')}
      cancelText={lang.get('common.actions.close')}
      okButtonProps={{ hidden: true }}
      width="small"
      open={open}
      onCancel={onClose}
    >
      <Paragraph className={styles.details__data}>
        <pre>{JSON.stringify(transaction.transferDetail, null, 2)}</pre>
      </Paragraph>
    </Modal>
  );
};

export default TransactionDetailsModal;
