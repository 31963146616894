import React, { FC } from 'react';

import { config } from 'data';
import { Info as InfoIcon } from 'components/icons';
import { Flex, Popover } from 'components/ui';
import { BusinessAccount } from 'types/models';

import styles from './styles.module.css';

import Details from './Details';
import Info from './Info';

type BusinessAccountBalanceProps = {
  businessAccount: BusinessAccount;
};

const BusinessAccountBalance: FC<BusinessAccountBalanceProps> = ({ businessAccount }) => {
  const balance = businessAccount.balance;
  const currency = balance?.currency ?? config.DEFAULT_CURRENCY;

  return (
    <Flex align="center" wrap="wrap">

      {balance?.details && (
        <Popover
          rootClassName={styles.popover}
          content={<Details details={balance.details} />}
          placement="bottomLeft"
        >
          <InfoIcon className={styles.popover__icon} />
        </Popover>
      )}

      <Info
        currentAmount={balance?.totalBalance ?? 0}
        incomingPendingAmount={balance?.totalIncomingPendingBalance ?? 0}
        outgoingPendingAmount={balance?.totalOutgoingPendingBalance ?? 0}
        currency={currency}
      />

    </Flex>
  );
};

export default BusinessAccountBalance;
