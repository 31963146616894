import React, { FC } from 'react';

import { IconProps } from 'types/components';

import Icon from './Icon';

const Briefcase: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path d="M4 6.25h12a1.5 1.5 0 0 1 1.5 1.5v7.5a1.5 1.5 0 0 1-1.5 1.5H4a1.5 1.5 0 0 1-1.5-1.5v-7.5A1.5 1.5 0 0 1 4 6.25Zm9 10.5v-12a1.5 1.5 0 0 0-1.5-1.5h-3A1.5 1.5 0 0 0 7 4.75v12" />
  </Icon>
);

export default Briefcase;
