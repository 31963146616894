import React, { FC } from 'react';
import { Link, useRouteError } from 'react-router-dom';

import { url } from 'helpers';
import { useLang } from 'hooks';
import { PageLayout } from 'layouts';
import { Button } from 'components/ui';
import { HttpStatus } from 'types/common';
import { ErrorPageProps } from 'types/pages';
import { RouteError } from 'types/routes';

const KNOWN_STATUSES: HttpStatus[] = [
  HttpStatus.FORBIDDEN,
  HttpStatus.NOT_FOUND,
];

const ErrorPage: FC<ErrorPageProps> = ({ customError, returnUrl }) => {
  const lang = useLang();
  const routeError = useRouteError() as RouteError;

  const error = customError ?? routeError;

  if ('statusText' in error && error.statusText) {
    error.message = error.statusText;
  }

  let title = lang.get('error.title');
  let heading = lang.get('error.heading');
  let message = error.message;

  if (KNOWN_STATUSES.includes(error.status as HttpStatus)) {
    title = lang.get(`error.${error.status}.title`);
    heading = lang.get(`error.${error.status}.heading`);
    message = lang.get(`error.${error.status}.message`);
  }

  return (
    <PageLayout
      title={title}
      heading={heading}
      caption={message}
    >
      <Link to={returnUrl ?? url.toDashboard()}>
        <Button block>{lang.get('error.back')}</Button>
      </Link>
    </PageLayout>
  );
};

export default ErrorPage;
