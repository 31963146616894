import { axios } from 'data';
import { Uuid } from 'types/common';
import { Client, ClientStatus } from 'types/models';
import { ClientParams, ClientsParams, ClientsResponse } from 'types/services';

import { preparePaginatableParams, preparePaginatedData } from './helpers';

const getClients = async (params: ClientsParams = {}) => {
  const response = await axios.get<ClientsResponse>('/core/admin/user-profile', {
    params: preparePaginatableParams(params),
  });

  return preparePaginatedData<Client>(response.data);
};

const getClient = async (id: Uuid) => {
  const response = await axios.get<Client>(`/core/admin/user-profile/${id}`);

  return response.data;
};

const updateClient = async (id: Uuid, params: Partial<ClientParams>) => {
  await axios.patch<never>(`/core/admin/user-profile/${id}`, params);
};

const activateClient = async (id: Uuid) => {
  await updateClient(id, { status: ClientStatus.ACTIVE });
};

const deactivateClient = async (id: Uuid) => {
  await updateClient(id, { status: ClientStatus.INACTIVE });
};

const verifyClient = async (id: Uuid) => {
  await axios.put<never>(`/core/admin/user-profile/${id}/verified`);
};

const unverifyClient = async (id: Uuid) => {
  await axios.put<never>(`/core/admin/user-profile/${id}/unverified`);
};

const clientService = {
  getClients,
  getClient,
  activateClient,
  deactivateClient,
  verifyClient,
  unverifyClient,
};

export default clientService;
