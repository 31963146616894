import { ThemeConfig } from 'antd';

enum Color {
  NONE = 'transparent',
  DARK = '#1d2939',
  LIGHT = '#ffffff',
  ERROR = '#f04438',
  WARNING = '#dc6803',
  SUCCESS = '#12b76a',
  PRIMARY = '#0073cc',
  PRIMARY_HOVER = '#2390d9',
  PRIMARY_ACTIVE = '#0058a6',
  PRIMARY_LIGHTEST = '#eff8ff',
  PRIMARY_LIGHTEST_HOVER = '#e2f2ff',
  PRIMARY_LIGHTEST_ACTIVE = '#b4deff',
  GRAY_100 = '#f9fafb',
  GRAY_200 = '#eaeef4',
  GRAY_300 = '#d0d5dd',
  GRAY_400 = '#a9bbd3',
  GRAY_500 = '#667085',
  GRAY_600 = '#4b6a94',
  GRAY_700 = '#314560',
  GRAY_800 = '#2a3c53',
  GRAY_900 = '#1d2939',
}

enum Gap {
  XS = 4,
  SM = 8,
  MD = 16,
  LG = 24,
}

const themeConfig: ThemeConfig = {
  token: {
    fontFamily: '"Inter", sans-serif',
    fontSize: 14,
    fontSizeLG: 14,
    fontSizeHeading1: 30,
    fontSizeHeading2: 24,
    fontSizeHeading3: 18,
    colorText: Color.DARK,
    colorLink: Color.PRIMARY,
    colorError: Color.ERROR,
    colorWarning: Color.WARNING,
    colorSuccess: Color.SUCCESS,
    colorPrimary: Color.PRIMARY,
    colorBorder: Color.GRAY_300,
    borderRadiusLG: 8,
  },
  components: {
    Breadcrumb: {
      colorBgTextHover: Color.NONE,
      itemColor: Color.GRAY_500,
      lastItemColor: Color.GRAY_500,
      linkColor: Color.GRAY_500,
      linkHoverColor: Color.PRIMARY,
      separatorColor: Color.GRAY_300,
    },
    Button: {
      fontWeight: 500,
      defaultShadow: 'none',
      dangerShadow: 'none',
      primaryShadow: 'none',
    },
    Divider: {
      colorSplit: Color.GRAY_300,
    },
    Drawer: {
      footerPaddingBlock: Gap.MD,
      footerPaddingInline: Gap.LG,
    },
    Form: {
      controlHeight: 40,
      itemMarginBottom: Gap.MD,
      labelHeight: 40,
    },
    Layout: {
      bodyBg: Color.NONE,
      headerBg: Color.LIGHT,
      headerPadding: 0,
    },
    Menu: {
      colorBgContainer: Color.NONE,
      itemPaddingInline: Gap.MD,
      activeBarHeight: 0,
      activeBarBorderWidth: 0,
    },
    Pagination: {
      fontWeightStrong: 500,
      colorBgTextHover: Color.PRIMARY_LIGHTEST,
      colorText: Color.GRAY_500,
      itemSize: 40,
      itemActiveBg: Color.PRIMARY_LIGHTEST,
      colorBgTextActive: Color.PRIMARY_LIGHTEST_ACTIVE,
    },
    Table: {
      fontWeightStrong: 500,
      colorBgContainer: Color.NONE,
      colorText: Color.GRAY_500,
      borderColor: Color.GRAY_200,
      headerBg: Color.NONE,
      headerColor: Color.GRAY_500,
      headerBorderRadius: 0,
      headerSplitColor: Color.NONE,
      rowHoverBg: Color.PRIMARY_LIGHTEST,
      padding: Gap.LG,
    },
    Tag: {
      defaultBg: Color.GRAY_200,
    },
    Tooltip: {
      fontSize: 12,
      controlHeight: 24,
    },
  },
};

export default themeConfig;
