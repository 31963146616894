import { config } from 'data';
import { clientService } from 'services';
import { useQuery } from 'hooks';
import { Uuid } from 'types/common';

const useClientQuery = (id?: Uuid) => useQuery({
  queryKey: [config.CLIENT_QUERY_KEY, id],
  queryFn: () => id ? clientService.getClient(id) : null,
});

export default useClientQuery;
