import { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { config } from 'data';
import { url } from 'helpers';
import { QueryModalData } from 'types/hooks';

import useModal from './modal';

const useQueryModal = <Params>(
  id: string,
  callback?: (params?: Params) => void,
) => {
  const modal = useModal();
  const [searchParams, setSearchParams] = useSearchParams();

  const updateQueryParams = useCallback((data?: QueryModalData<Params>) => {
    setSearchParams((prevSearchParams) => {
      const params = url.decodeParams(prevSearchParams.toString());
      const query = url.encodeParams({ ...params, ...url.prepareModalParams(data) });

      return new URLSearchParams(query);
    }, {
      replace: true,
    });
  }, [setSearchParams]);

  const show = useCallback((params?: Params) => {
    if (id) {
      updateQueryParams({ id, params });
    }

    callback?.(params);

    modal.show();
  }, [id, callback, modal.show, updateQueryParams]); /* eslint-disable-line react-hooks/exhaustive-deps */

  const hide = useCallback(() => {
    updateQueryParams();

    callback?.();

    modal.hide();
  }, [callback, modal.hide, updateQueryParams]); /* eslint-disable-line react-hooks/exhaustive-deps */

  useEffect(() => {
    const data = url.decodeParams(searchParams.toString())[config.MODAL_QUERY_PARAM] as QueryModalData<Params> | undefined;

    if (data && data.id === id) {
      show(data.params);
    }
  }, [id, show]); /* eslint-disable-line react-hooks/exhaustive-deps */

  return useMemo(() => ({
    open: modal.open,
    show,
    hide,
  }), [modal.open, show, hide]);
};

export default useQueryModal;
