import { axios } from 'data';
import { Uuid } from 'types/common';

import {
  FeeCommissionFrequency,
  Transaction,
  TransactionDirection,
  TransactionFeeType,
  TransactionStatus,
  TransactionType,
} from 'types/models';

import {
  TransactionCreateParams,
  TransactionsParams,
  TransactionsResponse,
  TransactionUpdateParams,
} from 'types/services';

import { preparePaginatableParams, preparePaginatedData, prepareTimezonableParams } from './helpers';

const getTransactions = async (params: TransactionsParams = {}) => {
  params = prepareTimezonableParams(params);
  params = preparePaginatableParams(params);

  const response = await axios.get<TransactionsResponse>('/payments/admin/transaction', { params });

  return preparePaginatedData<Transaction>(response.data);
};

const getTransaction = async (id: Uuid) => {
  const response = await axios.get<Transaction>(`/payments/admin/transaction/${id}`);

  return response.data;
};

const createTransaction = async (params: TransactionCreateParams) => {
  await axios.post<never>('/payments/admin/transaction', params);
};

const updateTransaction = async (id: Uuid, params: TransactionUpdateParams) => {
  await axios.patch<never>(`/payments/admin/transaction/${id}`, params);
};

const approveTransaction = async (id: Uuid) => {
  await updateTransaction(id, { status: TransactionStatus.COMPLETED });
};

const rejectTransaction = async (id: Uuid) => {
  await updateTransaction(id, { status: TransactionStatus.CANCELED });
};

const getTransactionAcceptedStatuses = (): TransactionStatus[] => [
  TransactionStatus.COMPLETED,
];

const getTransactionRejectedStatuses = (): TransactionStatus[] => [
  TransactionStatus.CANCELED,
  TransactionStatus.FAILED,
  TransactionStatus.INSUFFICIENT_FUNDS,
];

const getTransactionTypeDirections = (): Record<TransactionType, TransactionDirection[]> => ({
  [TransactionType.ADJUSTMENT]: [],
  [TransactionType.EXCHANGE]: [],
  [TransactionType.EXTERNAL]: [],
  [TransactionType.EXTERNAL_CREDIT]: [],
  [TransactionType.FALL_BELOW_FEE]: [],
  [TransactionType.FASTPAY]: [],
  [TransactionType.GLOBESEND]: [],
  [TransactionType.P2P]: [TransactionDirection.INCOMING, TransactionDirection.OUTGOING],
  [TransactionType.PAYNOW]: [],
  [TransactionType.SERVICE_FEE]: [],
  [TransactionType.TT]: [],
  [TransactionType.VA_OPEN]: [],
});

const getTransactionTypeFeeTypes = (): Record<TransactionType, TransactionFeeType[]> => ({
  [TransactionType.ADJUSTMENT]: [TransactionFeeType.FIXED, TransactionFeeType.PERCENT],
  [TransactionType.EXCHANGE]: [TransactionFeeType.FIXED, TransactionFeeType.PERCENT],
  [TransactionType.EXTERNAL]: [TransactionFeeType.FIXED, TransactionFeeType.PERCENT],
  [TransactionType.EXTERNAL_CREDIT]: [TransactionFeeType.FIXED, TransactionFeeType.PERCENT],
  [TransactionType.FALL_BELOW_FEE]: [TransactionFeeType.FIXED],
  [TransactionType.FASTPAY]: [TransactionFeeType.FIXED, TransactionFeeType.PERCENT],
  [TransactionType.GLOBESEND]: [TransactionFeeType.FIXED, TransactionFeeType.PERCENT],
  [TransactionType.P2P]: [TransactionFeeType.FIXED, TransactionFeeType.PERCENT],
  [TransactionType.PAYNOW]: [TransactionFeeType.FIXED, TransactionFeeType.PERCENT],
  [TransactionType.SERVICE_FEE]: [TransactionFeeType.FIXED],
  [TransactionType.TT]: [TransactionFeeType.FIXED, TransactionFeeType.PERCENT],
  [TransactionType.VA_OPEN]: [TransactionFeeType.FIXED],
});

const getTransactionTypeFrequencies = (): Record<TransactionType, FeeCommissionFrequency[]> => ({
  [TransactionType.ADJUSTMENT]: [],
  [TransactionType.EXCHANGE]: [],
  [TransactionType.EXTERNAL]: [],
  [TransactionType.EXTERNAL_CREDIT]: [],
  [TransactionType.FALL_BELOW_FEE]: [FeeCommissionFrequency.MONTHLY, FeeCommissionFrequency.QUARTERLY, FeeCommissionFrequency.ANNUALLY],
  [TransactionType.FASTPAY]: [],
  [TransactionType.GLOBESEND]: [],
  [TransactionType.P2P]: [],
  [TransactionType.PAYNOW]: [],
  [TransactionType.SERVICE_FEE]: [FeeCommissionFrequency.MONTHLY, FeeCommissionFrequency.QUARTERLY, FeeCommissionFrequency.ANNUALLY],
  [TransactionType.TT]: [],
  [TransactionType.VA_OPEN]: [],
});

const getTransactionTypeSupportsCountry = (): Record<TransactionType, boolean> => ({
  [TransactionType.ADJUSTMENT]: false,
  [TransactionType.EXCHANGE]: false,
  [TransactionType.EXTERNAL]: true,
  [TransactionType.EXTERNAL_CREDIT]: false,
  [TransactionType.FALL_BELOW_FEE]: false,
  [TransactionType.FASTPAY]: false,
  [TransactionType.GLOBESEND]: false,
  [TransactionType.P2P]: false,
  [TransactionType.PAYNOW]: false,
  [TransactionType.SERVICE_FEE]: false,
  [TransactionType.TT]: false,
  [TransactionType.VA_OPEN]: false,
});

const isTransactionTypeSupportsCountry = (transactionType: TransactionType) => Boolean(getTransactionTypeSupportsCountry()[transactionType]);

const isTransactionTypeSupportsFixedFee = (transactionType: TransactionType) => Boolean(getTransactionTypeFeeTypes()[transactionType].includes(TransactionFeeType.FIXED));

const isTransactionTypeSupportsPercentFee = (transactionType: TransactionType) => Boolean(getTransactionTypeFeeTypes()[transactionType].includes(TransactionFeeType.PERCENT));

const transactionService = {
  getTransactions,
  getTransaction,
  createTransaction,
  updateTransaction,
  approveTransaction,
  rejectTransaction,
  getTransactionAcceptedStatuses,
  getTransactionRejectedStatuses,
  getTransactionTypeDirections,
  getTransactionTypeFrequencies,
  isTransactionTypeSupportsCountry,
  isTransactionTypeSupportsFixedFee,
  isTransactionTypeSupportsPercentFee,
};

export default transactionService;
