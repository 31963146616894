import React from 'react';

import { Table as BaseTable } from 'components/ui';
import { TableProps as BaseTableProps } from 'types/components';
import { TableInstance } from 'types/hooks';

type TableProps<DataType> = Omit<
  BaseTableProps<DataType>,
  'dataSource' | 'pagination' | 'rowSelection' | 'onChange'
> & {
  table: TableInstance<DataType>;
};

const Table = <DataType extends object>({
  table,
  selectable,
  ...props
}: TableProps<DataType>) => (
  <BaseTable
    {...props}
    dataSource={table.data}
    pagination={table.pagination}
    rowSelection={selectable ? {
      onChange: (_, selectedData) => table.setSelectedData(selectedData),
    } : undefined}
    onChange={table.onChange}
  />
);

export default Table;
