import React from 'react';
import { useNavigate } from 'react-router-dom';

import { url } from 'helpers';
import { useLang } from 'hooks';
import { useFeeGroupsQuery } from 'hooks/queries';
import { Can, CardView } from 'components/layout';
import { Button } from 'components/ui';
import { UserPermission } from 'types/models';

const FeeGroupsView = () => {
  const lang = useLang();
  const navigate = useNavigate();

  const feeGroupsQuery = useFeeGroupsQuery();

  return (
    <Can permissions={[UserPermission.FEE_GROUPS_VIEW, UserPermission.FEE_GROUPS_UPDATE]}>
      <CardView
        title={lang.get('feeGroup.list.title')}
        caption={lang.choice('feeGroup.list.caption', feeGroupsQuery.data?.total ?? 0)}
        actions={(
          <Button
            color="primary"
            variant="filled"
            onClick={() => navigate(url.toFeeGroups())}
          >
            {lang.get('common.actions.view')}
          </Button>
        )}
      />
    </Can>
  );
};

export default FeeGroupsView;
