import { axios } from 'data';
import { Profile, Topic } from 'types/models';

import {
  ProfilePermissionsResponse,
  ProfileTopicsParams,
  ProfileTopicsResponse,
  ProfileUpdateParams,
  ProfileUpdatePasswordParams,
  ProfileUpdateTopic,
  ProfileUpdateTopicsParams,
} from 'types/services';

import { preparePaginatableParams, preparePaginatedData } from './helpers';

const getProfile = async () => {
  const response = await axios.get<Profile>('/core/admin/profile');

  return response.data;
};

const updateProfile = async (params: ProfileUpdateParams) => {
  await axios.patch<never>('/core/admin/profile', params);
};

const updatePassword = async (oldPassword: string, newPassword: string) => {
  const params: ProfileUpdatePasswordParams = { oldPassword, newPassword };

  await axios.post<never>('/core/admin/profile/change-password', params);
};

const getPermissions = async () => {
  const response = await axios.get<ProfilePermissionsResponse>('/core/admin/profile/permission');

  return response.data.list;
};

const getTopics = async (params: ProfileTopicsParams = {}) => {
  const response = await axios.get<ProfileTopicsResponse>('/payments/admin/profile/topic/subscribed', {
    params: preparePaginatableParams(params),
  });

  return preparePaginatedData<Topic>(response.data);
};

const updateTopics = async (topics: ProfileUpdateTopic[]) => {
  const params: ProfileUpdateTopicsParams = { topics };

  await axios.put<never>('/payments/admin/profile/topic', params);
};

const profileService = {
  getProfile,
  updateProfile,
  updatePassword,
  getPermissions,
  getTopics,
  updateTopics,
};

export default profileService;
